import * as React from 'react';
import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, CircularProgress, Avatar } from '@mui/material';

function SuspenseLoader() {
    React.useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    }, []);

    return (<>
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: -80,
                width: '100%',
                height: '100%'
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <div style={{ display: 'flex' }}>
                <Avatar style={{ top: '20px' }} src="https://mimavevablob.blob.core.windows.net/mimaveva/media/icon_mimaveva_medium.ico" />
                <h1 style={{ marginBottom: "0" }}>
                    <div>MIMAVEVA</div>
                </h1>
            </div>
            </Box>
        <Box
            sx={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%'
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            <CircularProgress size={64} disableShrink thickness={3} />
        </Box>
    </>);
}

export default SuspenseLoader;
