import { IProperty } from 'entities/index';
const servicePath = "/property";

export class PropertyService {

    public getProperty = async (propertyName: string): Promise<IProperty> => {
        return await get(`/${propertyName}`);
    }
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url);
    //const response = await fetch(url + '?' + new URLSearchParams(params), {
    //    method: 'GET',
    //    headers: {
    //        'x-api-key': "0eca5f9439914c65802a19491e957607"
    //    },
    //})

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}