import * as React from 'react';
import moment, { Moment } from 'moment';
import { Switch, FormGroup, FormControlLabel, styled, SwitchProps, useTheme, Button, Grid, Typography } from '@mui/material';
import { IMatch, IMatchEnrollment } from 'entities';
interface IProps {
    match: IMatch;
    userId?: number;
    membershipId?: number;
    showMatchDetails: () => void;
    updateMatch?: (match: IMatch) => void;
}

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginLeft: 15,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const renderLabel = (isAttending: boolean, color: string): JSX.Element => {
    return (<span style={{ marginLeft: '10px', color: `${color}`, fontStyle: 'italic' }}>
        <b>{isAttending ? "Aanwezig" : "Afwezig"}</b>
    </span>);
}

const MatchActions: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const [isAttending, setIsAttending] = React.useState<boolean | null>(null);

    React.useEffect(() => {
        if (props.userId == null || props.membershipId == null) {
            return;
        }

        const userEnrollment = props.match.enrollments.find(e => e.membership.id === props.membershipId);
        if (userEnrollment === null) {
            setIsAttending(false);
            return;
        }
        setIsAttending(userEnrollment?.status === "Aanwezig");
    }, [props.match])

    const onUpdate = (isAttending: boolean) => {
        if (props.updateMatch == null) {
            return;
        }

        const updateEnrollment = async (): Promise<IMatchEnrollment> => {
            const response = await fetch(`/match/enrollment`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "userId": props.userId,
                    "membershipId": props.membershipId,
                    "matchId": props.match.id,
                    "isAttending": isAttending
                })
            });
            return await response.json();
        }
        updateEnrollment().then(data => {
            const match = props.match;
            const enrollmentIndex = props.match.enrollments.findIndex(e => e.membership.id === props.membershipId);
            if (enrollmentIndex <= -1) {
                match.enrollments.push(data);
            } else {
                match.enrollments[enrollmentIndex] = data;
            }

            if (props.updateMatch == null) {
                return;
            }
            props.updateMatch(match);
        });

        setIsAttending(isAttending);
    }

    const currentDate = moment();
    const activeFrom = moment(props.match.matchDate).subtract(7, 'days'); 
    const activeUntil = moment(props.match.matchDate).subtract(2, 'days'); 
    activeUntil.hours(23);
    activeUntil.minutes(59);

    const isActive = moment(props.match.matchDate).isSameOrAfter(currentDate, 'days')
        && moment(currentDate).isSameOrAfter(activeFrom, 'days')
        && moment(currentDate).isSameOrBefore(activeUntil, 'seconds')

    const isUpcomming = moment(currentDate).isSameOrBefore(activeUntil, 'seconds');
    return (<>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                {(isActive && isAttending != null) && (<>
                    <FormGroup sx={{ marginBottom: '5px' }} >
                        <FormControlLabel
                            control={<IOSSwitch color={isAttending ? "success" : "error"} checked={isAttending} onChange={() => onUpdate(!isAttending)} name="Aangemeld" />}
                            label={renderLabel(isAttending, isAttending ? theme.colors.success.main : theme.colors.error.main)} />
                    </FormGroup>
                </>)}
                {(!isActive && isAttending != null) && (
                        <Typography variant="body1" color="text.secondary">
                        <div>{renderLabel(isAttending, isAttending ? theme.colors.success.main : "#ff1943b0")}</div>
                    </Typography>
                )}
            </Grid>
            <Grid item xs={6}>
                <Button sx={{ marginInlineEnd: 1, float: "right" }}  variant="outlined" size="small" onClick={() => props.showMatchDetails()}>
                    meer info
                </Button>
            </Grid>
            <Grid>
                {(isActive && isAttending != null) && (<>
                    <CountdownTimerAction targetDate={activeUntil} />
                </>)}
                {(!isActive && isAttending != null) && (<>
                    {isUpcomming && (<span style={{ marginLeft: '20px', color: '#808080ab', fontStyle: 'italic' }}>
                        Actief op: {moment(activeFrom).format('L')}
                    </span>)}
                </>)}
            </Grid>
        </Grid>
    </>);
}

export default MatchActions;

interface IPropsAction {
    targetDate: Moment
}

const CountdownTimerAction: React.FC<IPropsAction> = (props) => {
    const [currentTime, setCurrentTime] = React.useState(moment());
    const timeBetween = moment.duration(props.targetDate.diff(currentTime));

    React.useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(moment());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <span className="counter" style={{ marginLeft: '20px', color: '#808080ab', fontStyle: 'italic' }}>
                <span>{timeBetween.days()}d </span>
                <span>{timeBetween.hours()}h </span>
                <span>{timeBetween.minutes()}min </span>
                <span>{timeBetween.seconds()}s </span>
            </span>
        </>
    );
};

