import { IMatch } from 'entities/index';
const servicePath = "/match";

export class MatchService {
    public getMatch = async (matchId: string): Promise<IMatch | null> => {
        return await get(`/${ matchId }`);
    }

    public autEnrollmentMatch = async (matchId: string): Promise<IMatch | null> => {
        const url = `${servicePath}/${matchId}/enrollmentauto`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        });
        return await response.json();
    }

    public updateMatch = async (matchId: number, match: IMatch): Promise<IMatch | null> => {
        const url = servicePath;
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "matchId": matchId,
                "teamGoals": match.teamGoals,
                "opponentTeamGoals": match.opponentTeamGoals,
                "matchDate": match.matchDate,
                "teamId": match.team.id,
                "opponentTeamId": match.opponentTeam.id,
                "location": match.location
            })
        });
        return await response.json();
    }

    public createMatch = async (match: Partial<IMatch>): Promise<IMatch | null> => {
        debugger;
        if (match.team == null || match.opponentTeam == null) {
            return null;
        }
        const url = servicePath;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "teamGoals": 0,
                "opponentTeamGoals": 0,
                "matchDate": match.matchDate,
                "teamId": match.team.id,
                "opponentTeamId": match.opponentTeam.id,
                "location": match.location ?? ""
            })
        });
        return await response.json();
    }
}

export const getClubMatches = async (clubId: number): Promise<IMatch[] | null> => {
    return await get(`/club/${ clubId }`);
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params), {
        method: 'GET',
        headers: {
            'x-api-key': "0eca5f9439914c65802a19491e957607"
        },
    })

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}