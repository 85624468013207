import * as React from 'react';
import { Box } from '@mui/material';


const Loader = () => {
  return (
      <Box
          sx={{
              position: 'relative',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
      >
          <img width="350" src="https://mimavevablob.blob.core.windows.net/mimaveva/media/mimaveva-loading-soccer.gif" />
      </Box>
  );
}

export default Loader;
