import * as React from 'react';
import { Box, ToggleButtonGroup, ToggleButton, styled, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

// ICONS
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';

interface IProps {
    toggleView: string | null;
    onTabChange: (_event: React.SyntheticEvent, tabsValue: unknown) => void;
    updateView: (toggleView: string | null) => void;
}

const ManageMatchesTab: React.FC<IProps> = (props) => {
    const handleViewOrientation = (
        _event: React.MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        props.updateView(newValue);
    };

    return (<>
        <Box>
            <ToggleButtonGroup
                sx={{
                    mt: { xs: 2, sm: 1 }
                }}
                value={props.toggleView}
                exclusive
                onChange={handleViewOrientation}
            >
                <ToggleButton disableRipple value="table_view">
                    <TableRowsTwoToneIcon />
                </ToggleButton>
                <ToggleButton disableRipple value="grid_view">
                    <GridViewTwoToneIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    </>);
}

export default ManageMatchesTab;
