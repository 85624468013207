import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import * as React from 'react';
import { IMembership, IMembershipType, IUser } from 'entities';
import { UserService } from 'services/userService';
import { MembershipService } from '../../../../services/membershipService';

interface IProps {
    user: IUser;
    membership: IMembership;
    isOpen: boolean;
    onClose: (value: IMembership) => void;
}

const EditUserInfoDialog: React.FC<IProps> = (props) => {
    const [editMembership, setEditMembership] = React.useState<IMembership>(props.membership);
    const membershipService = new MembershipService();

    const onAmountChange = (value: string): void => {
        const amount = value;

        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
            const membership = { ...editMembership, amountDue: Number(value) };
            setEditMembership(membership);
        }
    }

    const onTypeChange = (value: string | null): void => {
        if (value == null) {
            return;
        }

        const membership = { ...editMembership, type: value as IMembershipType };
        setEditMembership(membership);

    }

    const onUpdate = (): void => {
        // Save the edited membership.
        membershipService.updateMembership(props.user.id, editMembership);
        props.onClose(editMembership);
    }

    return (<>
        <Box>
            <Dialog open={props.isOpen} onClose={onUpdate} sx={{
                "& .MuiDialog-container": {
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: "50px"
                }
            }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ paddingBottom: 0}}>
                    <h4>Lidmaatschap</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vul hieronder uw wijzigingen in.
                    </DialogContentText>
                    <FormControl fullWidth sx={{ marginTop: '20px' }}>
                        <InputLabel id="select-label">Type</InputLabel>
                        <Select
                            value={editMembership.type}
                            label="Lidmaatschap"
                            placeholder="Selecteer lidmaatschap"
                            onChange={(e) => onTypeChange(e.target.value)}
                        >
                            <MenuItem value="Aanvraag">Aanvraag</MenuItem>
                            <MenuItem value="Speler">Speler</MenuItem>
                            <MenuItem value="Rustend Lid">Rustend Lid</MenuItem>
                            <MenuItem value="Coach">Coach</MenuItem>
                            <MenuItem value="Financieel beheerder">Financieel beheerder</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        margin="normal"
                        id="team"
                        label="Team"
                        type="text"
                        fullWidth
                        variant="standard"
                        disabled={true}
                        value={editMembership.team.name}
                    />
                    <TextField
                        margin="normal"
                        id="saldo"
                        label="Saldo"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(e) => onAmountChange(e.target.value)}
                        value={editMembership.amountDue}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdate}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>);
}

export default EditUserInfoDialog;
