import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, CardContent, Card, Typography, Divider, styled, Switch, SwitchProps, Button, Avatar } from '@mui/material';
import { IMatch, ITeam } from 'entities';
import { MatchService } from 'services/matchService';
import Text from 'components/Text';
import MimavevaPage from '../../MimavevaPage';
import { LoadingButton } from '@mui/lab';
import EditMatchDialog from './Dialog/EditMatchDialog';

// ICONS
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ImagePath } from '../../../images';
import Loader from '../../Loader';

const EditMatch: React.FC = () => {
    const { matchId } = useParams();
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();

    const [selectedMatch, setSelectedMatch] = React.useState<IMatch | null>(null);
    const [isLoadingAutoEnrollment, setIsLoadingAutoEnrollment] = React.useState(false);
    const [isEditModeMatchInfo, setIsEditModeMatchInfo] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const matchService = new MatchService();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    React.useEffect(() => {
        if (matchId == null) {
            return;
        }
        setIsLoading(true);
        matchService.getMatch(matchId).then(data => {
            setSelectedMatch(data);
            setIsLoading(false);
        });

    }, [matchId]);

    const autoEnrollmentMatch = () => {
        if (matchId == null) {
            return;
        }

        setIsLoadingAutoEnrollment(true);
        matchService.autEnrollmentMatch(matchId).then(() => {
            setIsLoadingAutoEnrollment(false)
        });
    }

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 30, height: 30, marginRight: '15px', display: 'inline-block;', verticalAlign: 'middle' }}
        />
            <b>{team.name}</b>
        </>);
    }

    const matchDate = selectedMatch?.matchDate ? `${new Date(selectedMatch.matchDate).toLocaleDateString()} om ${new Date(selectedMatch.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` : null;
    return (<>
        <MimavevaPage>
            <Helmet>
                <title>Wedstrijd wijzigen</title>
            </Helmet>
            <Container sx={{ paddingTop: "20px", paddingBottom: "100px" }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                    marginBottom={5}
                >

                    <Grid item xs={12}>
                        <Card>
                            <Box
                                p={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        {t('Wedstrijd Gegevens')}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {t('Beheer informatie over wedstrijd.')}
                                    </Typography>
                                </Box>
                                <Button variant="text" startIcon={<EditTwoToneIcon />}
                                    onClick={() => setIsEditModeMatchInfo(!isEditModeMatchInfo)}>
                                    {t('WIJZIG')}
                                </Button>
                            </Box>
                            <Divider />
                            {isLoading && (<>
                                <Loader />
                            </>) || (<>
                                <CardContent
                                    sx={{
                                        p: 4
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        <Grid container spacing={0}>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Thuis')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    {selectedMatch?.team != null && (<>
                                                        {renderTeam(selectedMatch.team)}
                                                    </>)}
                                                </Text>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Uit')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    {selectedMatch?.opponentTeam != null && (<>
                                                        {renderTeam(selectedMatch.opponentTeam)}
                                                    </>)}</Text>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Datum')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{matchDate ? matchDate : "Onbekend"}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Adres')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{selectedMatch?.location ? selectedMatch.location : "Onbekend"}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Status')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{selectedMatch?.status}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Uitslag')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{selectedMatch?.teamGoals} - {selectedMatch?.opponentTeamGoals} </b>
                                                </Text>
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent></>
                                )}
                        </Card>
                    </Grid>

                    <Grid item xs={12}>
                        <Card>
                            <Box
                                p={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        {t('Acties')}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {t('Manage actions related to the match')}
                                    </Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <CardContent
                                sx={{
                                    p: 4
                                }}
                            >
                                <Typography variant="subtitle2">
                                    <Grid container spacing={0}>
                                        <LoadingButton loading={isLoadingAutoEnrollment} sx={{ marginInlineEnd: 1, float: "right" }} variant="contained" size="small" onClick={() => autoEnrollmentMatch()}>
                                            Automatisch Spelers Koppelen
                                        </LoadingButton>
                                    </Grid>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            {selectedMatch && (
                <EditMatchDialog isOpen={isEditModeMatchInfo} selectedMatch={selectedMatch}
                    onClose={() => setIsEditModeMatchInfo(false)}
                    onSave={(match: IMatch) => { setSelectedMatch(match); setIsEditModeMatchInfo(false); }} />
            )}
            {matchId == null && selectedMatch == null && (
                <EditMatchDialog isOpen={true} selectedMatch={null}
                    onClose={() => navigate('/manage/matches')}
                    onSave={(match: IMatch) => { setSelectedMatch(match); setIsEditModeMatchInfo(false); }} />
            )}
        </MimavevaPage>
    </>);
}

export default EditMatch;
