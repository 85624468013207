import { Box, Card, CardContent, CardMedia, Container, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../contexts/AuthContext';

import styles from 'styles/modules/app.module.scss';

interface IProps {
}

const MimavevaPage: React.FC<IProps> = (props) => {
    const { membership, loadMembership, user } = useAuth();

    React.useEffect(() => {
        if (membership === null && user != null) {
            loadMembership();
        }
    }, [user])

    return (
        <Box>
            {(membership == null || membership.type == "Aanvraag") && user?.role != "Admin" && (
                <Box className={styles.overview}>
                    <Helmet>
                        <title>Toegang geweigerd</title>
                    </Helmet>
                    <Container>
                        <Card>
                            <CardMedia
                                component="img"
                                height="140"
                                image="https://stanprucha.com/wp-content/uploads/2018/06/Artificial-Green-Grass-Football-Soccer-Field-Pitch-White-Stripe-Close-up-DSC01471-22-06-18.jpg"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    TOEGANG GEWEIGERD.
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    U hebt toestemming nodig voor toegang tot dit deel van deze site.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Container>
                </Box>
            ) || (props.children)}
        </Box>
    );
}
export default MimavevaPage;
