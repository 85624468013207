import * as React from 'react';
import { Box, Card, List, ListItemAvatar, ListItem, ListItemText, Avatar, Link, Typography, Divider, useTheme, styled, Tooltip, IconButton, Button } from '@mui/material';
import Text from 'components/Text';
import { IMembership, IUser } from 'entities';

// ICONS
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { UserService } from '../../services/userService';
import { MembershipService } from '../../services/membershipService';
import NotificationItem from './NotificationItem';
import NotificationListItem from './NotificationListItem';


const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

interface ITopScorer {
    user: IUser;
    membership: IMembership;
    name: string,
    image: string;
    //goals: number
}

interface IProps {
    limit?: number;
}

const NotificationsList: React.FC<IProps> = (props) => {
    const theme = useTheme();

    const [topScorersList, setTopScorersList] = React.useState<ITopScorer[]>([]);
    const [selectedTopscorer, setSelectedTopscorer] = React.useState<ITopScorer | null>(null);

    const userService = new UserService();
    const membershipService = new MembershipService();

    React.useEffect(() => {
      
    }, [])


    return (<>
        <Card variant="outlined">
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <EmojiEventsIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">Notifcaties</Typography>
                </Box>
            </Box>
            <Divider />

            <NotificationListItem
                mailbox={{
                    id: '1',
                    category: 'test',
                    subject: 'Hello World',
                    content: 'hello world, welcome to the app',
                    createdDate: new Date(),
                    from: {
                        name: 'Mimaveva',
                        email: 'mimaveva@',
                        avatar: 'https://www.iconpacks.net/icons/1/free-soccer-icon-459-thumb.png'
                    },
                    to: [],
                    date: 1,
                    opened: false,
                    summary: '',
                    tagIds: []
                }}
                selected={false}
            />

        </Card>
    </>);
}

export default NotificationsList;
