import { IEvent, IEventEnrollment } from 'entities/index';
const servicePath = "/event";

export class EventService {

    public getEvents = async (teamId: number): Promise<IEvent[]> => {
        return await get(`/team/${teamId }`);
    }

    public updateEnrollment = async (userId: number, eventId: number, isAttending: boolean, isAttendingPartner: boolean): Promise<IEventEnrollment> => {
            const response = await fetch(servicePath + `/enrollment`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "userId": userId,
                    "eventId": eventId,
                    "isAttending": isAttending,
                    "isAttendingPartner": isAttendingPartner
                })
            });
            return await response.json();
        }
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params), {
        method: 'GET',
        headers: {
            'x-api-key': "0eca5f9439914c65802a19491e957607"
        },
    })

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}