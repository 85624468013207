import * as React from 'react';
import { Box, Card, List, ListItemAvatar, ListItem, ListItemText, Avatar, Link, Typography, Divider, useTheme, styled, Tooltip, IconButton, Button, CardContent, CardMedia } from '@mui/material';
import Text from 'components/Text';
import { IMembership, IUser } from 'entities';

// ICONS
import ArrowUpwardTwoToneIcon from '@mui/icons-material/ArrowUpwardTwoTone';
import RemoveIcon from '@mui/icons-material/Remove';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';


import TropyGoldImage from '../../images/trophy-gold.png';
import TropySilverImage from '../../images/trophy-silver.jpg';
import TropyBronzeImage from '../../images/trophy-bronze.jpg';
import { UserService } from '../../services/userService';
import { MembershipService } from '../../services/membershipService';
import EditTopscorerDialog from './Dialog/EditTopscorerDialog';
import { useAuth } from '../../contexts/AuthContext';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

interface ITopScorer {
    user: IUser;
    membership: IMembership;
    name: string,
    image: string;
    //goals: number
}

interface IProps {
    limit?: number;
}

const TopscorersList: React.FC<IProps> = (props) => {
    const theme = useTheme();
    const { user } = useAuth();

    const [topScorersList, setTopScorersList] = React.useState<ITopScorer[]>([]);
    const [selectedTopscorer, setSelectedTopscorer] = React.useState<ITopScorer | null>(null);

    const userService = new UserService();
    const membershipService = new MembershipService();

    React.useEffect(() => {
        // Read user details with memberships.
        let newTopscorersList: ITopScorer[] = [];
        userService.getUsers().then(data => {
            data?.forEach((user) => {
                membershipService.getMembershipByUser(user.id).then(membership => {
                    if (membership == null) {
                        return;
                    }
                    const entity: ITopScorer = {
                        user,
                        membership,
                        name: user.username,
                        image: user.avatar,
                        //goals: membership.goals
                    };
                    newTopscorersList = [...newTopscorersList, entity];
                    setTopScorersList(newTopscorersList.filter(t => t.membership.type === "Speler") || []);
                });
            });
        });
    }, [])

    const updateGoals = (membership: IMembership) => {
        setTopScorersList((prev) => {
            const index = topScorersList.findIndex(m => m.membership.id === membership.id);

            const newPerson = { ...prev[index] }
            newPerson.membership.goals = membership.goals;
            prev[index] = newPerson

            return [...prev];
        })

        setSelectedTopscorer(null);
    }

    const renderPositionIcon = (player: ITopScorer): JSX.Element => {
        const position = topScorers.indexOf(player) + 1;
        switch (position) {
            case 1:
                return <img src={TropyGoldImage} width="20" height="20" />
            case 2:
                return <img src={TropySilverImage} width="20" height="20" />
            case 3:
                return <img src={TropyBronzeImage} width="20" height="20" />
            default:
                return <></>
        }
    }

    const renderPosition = (player: ITopScorer): JSX.Element => {
        const position = topScorers.indexOf(player) + 1;
        return <b>Positie:{position}e</b>
    }

    const topScorers = React.useMemo(() =>
        topScorersList.filter(s => s.membership.goals > 0).sort((a, b) => b.membership.goals - a.membership.goals)
        , [topScorersList, selectedTopscorer]);


    const isEditAllowed = user?.role == "Admin" || user?.role == "Teamleider";
    return (<>
        <Card variant="outlined">
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <EmojiEventsIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">TOPSCORERS</Typography>
                </Box>
                {isEditAllowed && (
                    <Tooltip placement="top" arrow title="Toevoegen">
                        <IconButton sx={{ alignSelf: 'center' }} color="primary"
                            onClick={() => setSelectedTopscorer(topScorersList[0] ?? null)}
                        >
                            <ControlPointTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                )}
            </Box>
            <Divider />
            {topScorers.length <= 0 && (
                <Typography sx={{ p: 5 }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >

                    {"Niet gevonden voor dit seizoen"}
                </Typography>
            )}
            <List sx={{ paddingTop: 2, fontSize: '14px' }}>
                {topScorers.length > 0 && (<>
                    <Card>
                        <Card sx={{
                            display: 'flex',
                            width: '100%',
                            boxShadow: 'none',
                            backgroundImage: 'url(https://img.freepik.com/premium-photo/soccer-ball-soccer-field-background_518421-220.jpg)',
                            backgroundSize: 'cover'
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', color: 'white' }}>
                                <CardMedia
                                    component="img"
                                    sx={{ flex: '1 0 auto', maxHeight: '90px', width: '80px' }}
                                    image={topScorers[0].image}
                                    alt={topScorers[0].name}
                                />
                                <CardContent sx={{ py: '5px !important', color: 'white' }}>
                                    <Typography sx={{ color: 'white' }} variant="subtitle2" noWrap>
                                        {renderPositionIcon(topScorers[0])} <b>{renderPosition(topScorers[0])}</b>
                                    </Typography>
                                    <Typography sx={{ color: 'white' }} variant="h4">
                                        <Link sx={{ color: 'white' }} onClick={() => isEditAllowed && setSelectedTopscorer(topScorers[0])} color="text.primary" variant="h4">
                                            {topScorers[0].name}
                                        </Link>
                                    </Typography>
                                    <Typography sx={{ color: 'white' }} variant="subtitle2" noWrap>
                                        Doelpunten:   {topScorers[0].membership.goals}
                                    </Typography>
                                </CardContent>
                            </Box>
                        </Card>
                        {topScorers.slice(1, props.limit || topScorers.length).map(player => (<div key={player.name}>
                            <ListItem
                                sx={{ px: 2, py: 1.95 }}>
                                <ListItemAvatar
                                    sx={{ mr: 2, minWidth: 0 }}>
                                    <Avatar src={player.image} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<>
                                        <Link onClick={() => isEditAllowed && setSelectedTopscorer(player)} color="text.primary" variant="h5">
                                            {player.name}
                                        </Link>
                                    </>
                                    }
                                    secondary={
                                        <Typography variant="subtitle2" noWrap>
                                            {renderPositionIcon(player)} {renderPosition(player)}
                                        </Typography>
                                    }
                                />
                                <Box display="flex" alignItems="center">
                                    <Typography
                                        sx={{ pr: 1 }}
                                        variant="h3"
                                    >
                                        <Text color="secondary">{player.membership.goals}</Text>
                                    </Typography>
                                </Box>
                            </ListItem>
                            <Divider />
                        </div>))}
                        {isEditAllowed && (
                            <Button
                                variant="text"
                                fullWidth={true}
                                type="submit"
                                sx={{ marginTop: '15px', marginBottom: '15px' }}
                                onClick={() => setSelectedTopscorer(topScorersList[0] ?? null)}
                            >
                                TOPSCORER TOEVOEGEN  <ControlPointTwoToneIcon sx={{ marginLeft: '10px' }} />
                            </Button>
                        )}
                    </Card>
                </>)}
            </List>
        </Card>
        {selectedTopscorer != null && (<>
            <EditTopscorerDialog isOpen={true} user={selectedTopscorer.user} membership={selectedTopscorer.membership}
                onClose={(m) => { updateGoals(m) }} />
        </>)}
    </>);
}

export default TopscorersList;
