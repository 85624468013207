import * as React from 'react';
import { Suspense, lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import SidebarLayout from './layouts/SidebarLayout/SideBarLayout';
import SuspenseLoader from './components/SuspenseLoader';
import Overview from './components/Overview';
import ManageUsers from './components/Management/Users/ManageUsers';
import ManageMatches from './components/Management/Matches/ManageMatches';
import MatchesAgenda from './components/Agenda/MatchesAgenda';
import UserFinance from './components/Finance/UserFinance';
import TopscorersPage from './components/Topscorers/TopscorersPage';
import ManageUserProfile from './components/Management/Users/ManageUserProfile';
import ManageFinance from './components/Management/Finance/ManageFinance';
import ManageMembershipRequests from './components/Management/Membership/ManageMembershipRequests';
import MimavevaPage from './components/MimavevaPage';
import NotificationsPage from './components/Notifications/NotificationsPage';
import EditMatch from './components/Management/Matches/EditMatch';
import EventsAgenda from './components/Agenda/EventsAgenda';
import MaterialAccountable from './components/MaterialAccountable/MaterialAccountable';

const Loader = (Component: any) => (props: any) =>
(
    <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
    </Suspense>
);

// Pages
const routes: RouteObject[] = [
    {
        path: '',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="/overview" replace />
            },
            {
                path: 'index.html',
                element: <Overview />
            },
            {
                path: 'overview',
                element: <Overview />
            },
            {
                path: 'agenda/matches',
                element: (<MimavevaPage><MatchesAgenda /></MimavevaPage>)
            },
            {
                path: 'agenda/events',
                element: (<MimavevaPage><EventsAgenda /></MimavevaPage>)
            },
            {
                path: 'topscorers',
                element: (<MimavevaPage><TopscorersPage /></MimavevaPage>)
            },
            {
                path: 'notifications',
                element: (<MimavevaPage><NotificationsPage /></MimavevaPage>)
            },
            {
                path: 'notifications/:notificationId/details',
                element: (<MimavevaPage><NotificationsPage /></MimavevaPage>)
            },
            {
                path: 'notifications/:notificationId/users/:userId/details',
                element: (<MimavevaPage><NotificationsPage /></MimavevaPage>)
            },
            {
                path: 'my/finance',
                element: (<MimavevaPage><UserFinance /></MimavevaPage>)
            },
            {
                path: 'manage/users/:pageNumber',
                element: (<MimavevaPage><ManageUsers /></MimavevaPage>)
            },
            {
                path: 'manage/users',
                element: (<MimavevaPage><ManageUsers /></MimavevaPage>)
            },
            {
                path: 'manage/users/my/details',
                element: <ManageUserProfile />
            },
            {
                path: 'manage/users/:userId/details',
                element: (<MimavevaPage><ManageUserProfile /></MimavevaPage>)
            },
            {
                path: 'manage/memberships',
                element: (<MimavevaPage><ManageMembershipRequests /></MimavevaPage>)
            },
            {
                path: 'manage/matches',
                element: (<MimavevaPage><ManageMatches /></MimavevaPage>)
            },
            {
                path: 'manage/match/:matchId/details',
                element: (<MimavevaPage><EditMatch /></MimavevaPage>)
            },
            {
                path: 'manage/match/create',
                element: (<MimavevaPage><EditMatch /></MimavevaPage>)
            },
            {
                path: 'manage/finance',
                element: (<MimavevaPage><ManageFinance /></MimavevaPage>)
            },
            {
                path: '/accountable/material',
                element: (<MimavevaPage><MaterialAccountable /></MimavevaPage>)
            },
            {
                path: 'status',
                children: [
                    {
                        path: '',
                        element: <Navigate to="404" replace />
                    },
                ]
            }
        ]
    },
    {
        path: 'management',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Navigate to="transactions" replace />
            },
            {
                path: 'transactions',
                element: <Overview />
            },
            {
                path: 'profile',
                children: [
                    {
                        path: '',
                        element: <Navigate to="details" replace />
                    },
                    {
                        path: 'details',
                        element: <ManageUserProfile />
                    },
                    {
                        path: 'settings',
                        element: <Overview />
                    }
                ]
            }
        ]
    }
];

export default routes;
