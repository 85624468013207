import * as React from 'react';
import moment from 'moment';
import { Box, Card, Divider, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Avatar, Chip, TablePagination, Tooltip, IconButton, TextField, InputAdornment } from '@mui/material';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IUserRole, IUser } from 'entities';
import CustomLabel from '../../CustomLabel';


// ICONS
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';

interface IProps {
    users: IUser[];
    query: string;
    pageNumber: number;
    filteredUsers: IUser[];
    selectedItems: string[];
    isSelectedBulkActions: boolean;
    isSelectedAllUsers: boolean;
    isSelectedSomeUsers: boolean;
    onUpdateSearchQuery: (query: string) => void;
    handleSelectAllUsers: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectOneUser: (event: React.ChangeEvent<HTMLInputElement>, userId: string) => void;
}

const ManageUsersList: React.FC<IProps> = (props) => {
    const { t }: { t: any } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
   
    const [page, setPage] = React.useState<number>(Number(props.pageNumber) ?? 0);
    const [limit, setLimit] = React.useState<number>(10);

    const handlePageChange = (_event: any, newPage: number): void => {
        navigate('/manage/users/' + newPage);
        setPage(newPage);
    };

    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => setLimit(parseInt(event.target.value));

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        props.onUpdateSearchQuery(event.target.value);
        setPage(0);
    };


    //React.useEffect(() => setPage(0), [props.filteredUsers]);

    const renderRoleLabel = (userRole: IUserRole): JSX.Element => {
        switch (userRole) {
            case 'Admin':
                return <Chip label="Admin" color="error" />
            case 'Teamleider':
                return <Chip label="Teamleider" color="info" />
            case 'Speler':
                return <Chip label="Speler" color="primary" />
            case 'Rustend Lid':
                return <Chip label="Rustend lid" color="warning" />
            case 'Lid':
                return <Chip label="Lid" color="primary" />
            case 'Financieel beheerder':
                return <Chip label="Financieel beheer" color="secondary" />
            case 'Aanvraag':
                return <Chip label="Aanvraag" color="warning" />
            default:
                return <Chip label="Onbekend" color="info" />
        }    
    };

    const applyPagination = (users: IUser[], page: number, limit: number): IUser[] => {
        return users.slice(page * limit, page * limit + limit);
    };
    const paginatedUsers = applyPagination(props.filteredUsers, page, limit);

    return (<>
        <Card>
            <Box p={2}>
                {!props.isSelectedBulkActions && (
                    <TextField sx={{ m: 0 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleQueryChange}
                        placeholder={t('Search by name, email or username...')}
                        value={props.query}
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                )}
                {props.isSelectedBulkActions && <>The Bulkactions should be rendered here..</>}
            </Box>
            <Divider />
            {paginatedUsers.length === 0 ? (
                <>
                    <Typography sx={{ py: 10 }}
                        variant="h3"
                        fontWeight="normal"
                        color="text.secondary"
                        align="center"
                    >
                        {t("We couldn't find any users matching your search criteria")}
                    </Typography>
                </>
            ) : (
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={props.isSelectedAllUsers}
                                            indeterminate={props.isSelectedSomeUsers}
                                            onChange={props.handleSelectAllUsers}
                                        />
                                    </TableCell>
                                    <TableCell>{t('Avatar')}</TableCell>
                                    <TableCell>{t('Naam')}</TableCell>
                                    <TableCell>{t('Email')}</TableCell>
                                    <TableCell>{t('Laatste login')}</TableCell>
                                    <TableCell>{t('Status')}</TableCell>
                                    <TableCell>{t('Role')}</TableCell>
                                    <TableCell align="center">{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedUsers.map((user) => {
                                    const isUserSelected = props.selectedItems.includes(user.id.toString());
                                    return (
                                        <TableRow hover key={user.id} selected={isUserSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isUserSelected}
                                                    onChange={(event) => props.handleSelectOneUser(event, user.id.toString())}
                                                    value={isUserSelected}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar
                                                        src={user.avatar}
                                                    />
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h5">
                                                    {user.username}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{user.email}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{user.latestLogin ? moment(user.latestLogin).local().fromNow() : '-'}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {user.isActive && (
                                                    <CustomLabel color="success">
                                                        <DoneTwoToneIcon fontSize="small" />
                                                        <b>{t('Actief')}</b>
                                                    </CustomLabel>
                                                ) || (
                                                        <CustomLabel color="error">
                                                            <ClearIcon fontSize="small" />
                                                            <b>{t('Inactief')}</b>
                                                        </CustomLabel>
                                                    )}
                                            </TableCell>
                                            <TableCell>{renderRoleLabel(user.role)}</TableCell>
                                            <TableCell align="center">
                                                <Typography noWrap>
                                                    <Tooltip title={t('View/Edit')} arrow>
                                                        <IconButton
                                                            component={RouterLink}
                                                            to={
                                                                `/${location.pathname.split('/')[1]
                                                                }/users/${user.guid}/details/`
                                                            }
                                                            color="primary"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t('Delete')} arrow>
                                                        <IconButton
                                                            //onClick={handleConfirmDelete}
                                                            color="primary"
                                                        >
                                                            <DeleteTwoToneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box p={2}>
                        <TablePagination
                            component="div"
                            count={props.filteredUsers.length}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 15]}
                        />
                    </Box>
                </>
            )}
        </Card>
    </>);
}

export default ManageUsersList;
