import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, ToggleButtonGroup, ToggleButton, styled, Tabs } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { IUserRole } from 'entities';

// ICONS
import GridViewTwoToneIcon from '@mui/icons-material/GridViewTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';

interface IProps {
    toggleView: string | null;
    filters: Filters;
    onTabChange: (_event: React.SyntheticEvent, tabsValue: unknown) => void;
    updateView: (toggleView: string | null) => void;
}

interface Filters {
    role?: IUserRole;
}

const TabsWrapper = styled(Tabs)(
    ({ theme }) => `
    @media (max-width: ${theme.breakpoints.values.md}px) {
      .MuiTabs-scrollableX {
        overflow-x: auto !important;
      }

      .MuiTabs-indicator {
          box-shadow: none;
      }
    }
    `
);

const ManageUsersTabs: React.FC<IProps> = (props) => {
    const { user } = useAuth();
    const { t }: { t: any } = useTranslation();

    const tabs = [
        {
            value: 'all',
            label: t('Alle gebruikers')
        },
        {
            value: 'Lid',
            label: t('Leden')
        },
        {
            value: 'Teamleider',
            label: t('Teamleiders')
        },
        {
            value: 'Aanvraag',
            label: t('Aanvragen')
        }
    ];

    if (user?.role === "Admin") {
        tabs.push({
            value: 'Admin',
            label: t('Administrators')
        });
    }

    const handleViewOrientation = (
        _event: React.MouseEvent<HTMLElement>,
        newValue: string | null
    ) => {
        props.updateView(newValue);
    };

    return (<>
        <Box>
            <TabsWrapper
                onChange={props.onTabChange}
                scrollButtons="auto"
                textColor="secondary"
                value={props.filters.role || 'all'}
                variant="scrollable"
            >
                {tabs.map((tab) => (
                    <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
            </TabsWrapper>
            <ToggleButtonGroup
                sx={{
                    mt: { xs: 2, sm: 1 }
                }}
                value={props.toggleView}
                exclusive
                onChange={handleViewOrientation}
            >
                <ToggleButton disableRipple value="table_view">
                    <TableRowsTwoToneIcon />
                </ToggleButton>
                <ToggleButton disableRipple value="grid_view">
                    <GridViewTwoToneIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
    </>);
}

export default ManageUsersTabs;
