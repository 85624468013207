import React from 'react';
import { Grid, Typography, Avatar, useTheme, styled } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

interface IProps {
    clubName: string;
}

const PageHeader: React.FC<IProps> = (props) => {
    const { user } = useAuth();
    const theme = useTheme();

    if (user == null) {
        return <></>;
    }

    return (
        <Grid container alignItems="center">
            <Grid item>
                <Avatar
                    sx={{
                        display: {
                            xs: 'none',
                            md: 'block'
                        },
                        marginRight: 2,
                        width: theme.spacing(8),
                        height: theme.spacing(8),
                        backgroundColor: 'cadetblue'
                    }}
                    variant="circular"
                    alt={user.firstName}
                    src={user.avatar}
                />
            </Grid>
            <Grid item >
                <Typography variant="h1" component="h1" gutterBottom style={{ color: 'white' }}>
                    Welkom {user.username}
                </Typography>
                <Typography variant="subtitle2" style={{ color: 'white' }}>
                    <b>Voetbalclub:</b> {props.clubName}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default PageHeader;
