import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, Divider, Typography, styled, Avatar, Grid, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';

// IMAGES
import RabobankQR from 'images/svreeshof-rabobank.png';

// ICONS
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PaymentIcon from '@mui/icons-material/Payment';
import BackpackIcon from '@mui/icons-material/Backpack';
import styles from 'styles/modules/app.module.scss';
import MimavevaPage from '../MimavevaPage';
import CustomizedTimeline from './AccountableTimeline';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const MaterialAccountable: React.FC = () => {
    const { user, membership } = useAuth();

    return (
        <MimavevaPage>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Materiaal</title>
                </Helmet>
                <Container sx={{ marginBottom: '120px' }}>
                    <Card variant="outlined" >
                        <Box
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Box display="flex" alignItems="center">
                                <AvatarPrimary sx={{ mr: 1.5 }}>
                                    <BackpackIcon />
                                </AvatarPrimary>
                                <Typography variant="h4">OPHALEN/WEGBRENGEN TEAMTAS</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        {/*<Box*/}
                        {/*    sx={{ paddingLeft: 2, paddingRight: 2 } }*/}
                        {/*    display="flex"*/}
                        {/*    alignItems="center"*/}
                        {/*    justifyContent="space-between"*/}
                        {/*>*/}
                        {/*        <Typography>*/}
                        {/*            Verantwoordelijk voor het ophalen en wegbrengen van de Teamtas.*/}
                        {/*    </Typography>*/}
                        {/*        </Box>*/}
                        <CustomizedTimeline />
                    </Card>

                </Container>
            </Box>
        </MimavevaPage>
    );
}

export default MaterialAccountable;