import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Button } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { IEvent } from 'entities';
import styles from 'styles/modules/app.module.scss';
import MimavevaPage from '../MimavevaPage';
import Footer from '../Footer';
import Events from '../Events/Events';
import EventEnrollments from '../Events/EventEnrollments';

import { EventService } from '../../services/eventService';

// ICONS
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';


const EventsAgenda: React.FC = () => {
    const { user, membership, isLoadingAuth } = useAuth();
    const [events, setEvents] = React.useState<IEvent[]>([]);
    const [selectedEvent, setSelectedEvent] = React.useState<IEvent | null>(null);

    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (membership === null) {
            return;
        }
        setIsLoading(true);

        const eventService = new EventService();
        eventService.getEvents(membership.team.id).then(data => {
            if (data === null) {
                return;
            }
            setEvents(data);
            setIsLoading(false);
        });
    }, [membership])

    const isLoadingAll = isLoading || isLoadingAuth;
    return (
        <MimavevaPage>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Evenementen</title>
                </Helmet>
                <Container>
                    {(user != null && membership != null) && (<>
                        {(selectedEvent != null) && (<>
                            <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 2 }}
                                variant="contained"
                                endIcon={<KeyboardReturnIcon />}
                                onClick={() => setSelectedEvent(null)}
                            >Terug
                            </Button>
                            <EventEnrollments event={selectedEvent} enrollments={selectedEvent.enrollments} />
                        </>)}
                        {(selectedEvent === null) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={4}
                                marginBottom={5}
                            >
                                <Grid item xs={12} md={6}>
                                    <Events title={`AANKOMENDE EVENEMENTEN`}
                                        membership={membership}
                                        isLoading={isLoadingAll}
                                        userId={user.id}
                                        events={events}
                                        limit={99999}
                                        showEventDetails={(event: IEvent) => setSelectedEvent(event)}
                                        updateEvent={() => { } }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>)}
                </Container>
                <Footer />
            </Box>
        </MimavevaPage>
    );
}

export default EventsAgenda;