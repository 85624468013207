import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { IUser } from 'entities';
import { UserService } from 'services/userService';
import moment from 'moment';

interface IProps {
    selectedUser: IUser;
    isOpen: boolean;
    onClose: (user: IUser) => void;
}

const EditAccountDialog: React.FC<IProps> = (props) => {
    const [editUser, setEditUser] = React.useState<IUser>(props.selectedUser);
    //const [selectedBirthday, setValue] = React.useState<string | null>(new Date().to);

    const userService = new UserService();

    const onChangeName = (value: string): void => {
        const user = { ...editUser, username: value };
        setEditUser(user);
    }

    const onChangeAddress = (value: string): void => {
        const user = { ...editUser, address: value };
        setEditUser(user);
    }

    const onChangeDateOfBirth = (value: string): void => {
        const user = { ...editUser, dateOfBirth: value ? new Date(value) : null };
        setEditUser(user);
    }

    const onUpdate = (): void => {
        // Sanitize user before update.
        const saveUser = {
            ...editUser,
            username: editUser.username?.trim(),
            address: editUser.address?.trim()
        };
        // Save the edited user.
        userService.updateUser(editUser.guid, saveUser);
        props.onClose(editUser);
    }

    return (<>
        <Box>
            <Dialog open={props.isOpen} onClose={onUpdate} sx={{
                "& .MuiDialog-container": {
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: "50px"
                }
            }} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ paddingBottom: 0 }}>
                    <h4>Persoonlijke gegevens</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Vul hieronder uw wijzigingen in.
                    </DialogContentText>
                    <TextField
                        margin="normal"
                        id="name"
                        label="Naam"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={editUser.username}
                        onChange={(e) => onChangeName(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        id="email"
                        label="Emailadres"
                        type="email"
                        fullWidth
                        variant="standard"
                        value={props.selectedUser.email}
                        disabled={true}
                    />
                    <TextField
                        margin="normal"
                        id="adres"
                        label="Adres"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={editUser.address ? editUser.address : " "}
                        onChange={(e) => onChangeAddress(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        id="date"
                        label="Verjaardag"
                        type="date"
                        fullWidth
                        variant="standard"
                        value={editUser.dateOfBirth ? moment(editUser.dateOfBirth).format("YYYY-MM-DD") : null}
                        onChange={(e) => onChangeDateOfBirth(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdate}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>);
}

export default EditAccountDialog;
