import { IMembership } from 'entities';
const servicePath = "/membership";

export class MembershipService {

    public getMemberships = async (): Promise<IMembership[]> => {
        return await get("/all");
    }

    public addMembershipRequest = async (userId: number): Promise<IMembership | null> => {
        const url = servicePath;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userId": userId,
            })
        });
        return await response.json();
    }

    public updateMembership = async (userId: number, membership: IMembership): Promise<IMembership | null> => {
        const url = servicePath + '/details';
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userId": userId,
                "type": membership.type,
                "amountDue": membership.amountDue,
                "goals": membership.goals
            })
        });
        return await response.json();
    }

    public getMembershipByUser = async (userId: number): Promise<IMembership | null> => {
        return await get("/details", {
            userId,
        });
    }

    public getMembershipRequests = async (): Promise<IMembership[] | null> => {
        return await get("/requests", {});
    }
}

export const getMembership = async (): Promise<IMembership | null> => {
    return await get();
}

export const getMembershipByUser = async (userId: number): Promise<IMembership | null> => {
    return await get("/details", {
        userId,
    });
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params))

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}

export const updateAutoEnrollment = async (userId: number, autoEnrollment: boolean): Promise<IMembership | null> => {
    const url = servicePath + '/autoEnrollment';
    const response = await fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "userId": userId,
            "autoEnrollment": autoEnrollment,
        })
    });
    return await response.json();
}