import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useTheme, Box, Container, Card, CardActionArea, CardMedia, CardContent, Typography, Button } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { getClubMatches } from "services/matchService";
import { IMatch } from 'entities';
import DashboardStatistics from './Statistics/DashboardStatistics';
import SuspenseLoader from './SuspenseLoader';
import Footer from './Footer';

import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';

import styles from 'styles/modules/app.module.scss';
import { MembershipService } from '../services/membershipService';

function Overview() {
    const theme = useTheme();
    const { membership, loadMembership, user, isLoadingMembership } = useAuth();
    const [isLoadingMatches, setIsLoadingMatches] = React.useState<boolean>(false);
    const [matches, setMatches] = React.useState<IMatch[]>([]);

    const membershipService = new MembershipService();

    React.useEffect(() => {
        if (membership === null && user != null) {
            loadMembership();
        }
    }, [user])

    React.useEffect(() => {
        if (user == null || membership == null) {
            return;
        }
        setIsLoadingMatches(true);
        // Load the matches of the current membership club.
        getClubMatches(membership.team.id).then(data => {
            setMatches(data || [])
            setIsLoadingMatches(false);
        });
    }, [user, membership])

    const onRequestMembership = (): void => {
        if (user == null || membership != null) {
            return;
        }
        membershipService.addMembershipRequest(user.id).then(() => loadMembership());
    }

    return (
        <Box className={styles.overview}>
            <Helmet>
                <title>Overview</title>
            </Helmet>
            <Container>
                {(user != null && membership != null && membership.type !== "Aanvraag") && (<>
                    <DashboardStatistics membership={membership} matches={matches} isLoading={isLoadingMatches} user={user} />
                    <Footer />
                </>) || (user != null && !isLoadingMembership) && (
                    <>
                        <Card>
                            <CardMedia
                                component="img"
                                height="140"
                                image="https://stanprucha.com/wp-content/uploads/2018/06/Artificial-Green-Grass-Football-Soccer-Field-Pitch-White-Stripe-Close-up-DSC01471-22-06-18.jpg"
                                alt="green iguana"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    GEEN LIDMAATSCHAP GEVONDEN.
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Er is nog geen team of lidmaatschap gekoppeld aan dit account.
                                    Raadpleeg de teamleider om u toe te voegen als speler van het team.
                                </Typography>

                                    <Box sx={{ marginTop: '20px' }}>
                                {(membership != null && membership.type == "Aanvraag") && (
                                    <Typography variant="body2" color="text.secondary"
                                            sx={{
                                                color: `${theme.colors.success.main}`,
                                            fontSize: `${theme.typography.pxToRem(16)}`
                                        }}
                                    >
                                            Uw aanvraag is succesvol ingediend.
                                    </Typography>) || (
                                        <Button
                                            sx={{
                                                textTransform: 'uppercase',
                                                fontSize: `${theme.typography.pxToRem(12)}`
                                            }}
                                            variant="contained"
                                            endIcon={<KeyboardArrowRightTwoToneIcon />}
                                            onClick={onRequestMembership}
                                        >
                                            Aanvraag indienen
                                        </Button>
                                )}
                                    </Box>

                            </CardContent>
                        </Card>
                        <Footer />
                    </>
                ) || <SuspenseLoader />}
            </Container>
        </Box>
    );
}

export default Overview;