import * as  React from 'react';
import { useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../../contexts/AuthContext';

import {
    Avatar,
    Box,
    Button,
    Divider,
    Hidden,
    lighten,
    List,
    ListItem,
    ListItemText,
    Popover,
    Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.colors.alpha.white[100]};
        display: block;
`
);

const UserBoxLabelBox = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${lighten(theme.colors.secondary.main, 0.8)}
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${lighten(theme.colors.secondary.main, 0.8)}
`
);

function HeaderUserbox() {
    const { isAuthenticated, logout, user, membership } = useAuth();
    const navigate = useNavigate();

    const userDummy = {
        name: 'John Doe',
        avatar: '/static/images/avatars/1.jpg',
        jobtitle: 'Speler'
    };


    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            {isAuthenticated && (<>
                <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                    <Avatar variant="rounded" alt={user ? user.firstName : userDummy.name} src={user ? user.avatar : ""} />
                    <Hidden mdDown>
                        <UserBoxText>
                            <UserBoxLabel variant="body1">{user ? user.username : ""}</UserBoxLabel>
                            <UserBoxDescription variant="body2">
                                {user ? user.role : userDummy.jobtitle}
                            </UserBoxDescription>
                        </UserBoxText>
                    </Hidden>
                    <Hidden smDown>
                        <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
                    </Hidden>
                </UserBoxButton>
                <Popover
                    anchorEl={ref.current}
                    onClose={handleClose}
                    open={isOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <MenuUserBox sx={{ minWidth: 200 }} display="flex">
                        <Avatar variant="rounded" alt={userDummy.name} src={user ? user.avatar : ""} />
                        <UserBoxText>
                            <UserBoxLabelBox variant="body1">{user ? user.username : ""} </UserBoxLabelBox>
                            <UserBoxDescription variant="body2">
                                {user ? user.role : userDummy.jobtitle}
                            </UserBoxDescription>
                        </UserBoxText>
                    </MenuUserBox>
                    <Divider sx={{ mb: 0 }} />
                    <List sx={{ p: 1 }} component="nav">
                        <ListItem button to="/manage/users/my/details" component={NavLink}>
                            <AccountBoxTwoToneIcon fontSize="small" />
                            <ListItemText primary="Mijn Profiel" onClick={handleClose} />
                        </ListItem>
                        {(membership != null && membership.team.name === "SV Reeshof 7") && (
                            <ListItem
                                button
                                to="/my/finance"
                                component={NavLink}
                            >
                                <EuroSymbolIcon fontSize="small" />
                                <ListItemText primary="Mijn Saldo" onClick={handleClose} />
                            </ListItem>
                        )}
                    </List>
                    <Divider />
                    <Box sx={{ m: 1 }}>
                        <Button onClick={() => logout()} color="primary" fullWidth>
                            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                            Uitloggen
                        </Button>
                    </Box>
                </Popover></>) || (<>Login</>)}
        </>
    );
}

export default HeaderUserbox;
