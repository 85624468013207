import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { IMembership, IUser } from 'entities';
import { MembershipService } from 'services/membershipService';
import NumberInput from './NumberInput';

interface IProps {
    user: IUser;
    membership: IMembership;
    isOpen: boolean;
    onClose: (value: IMembership) => void;
}

const EditTopscorerDialog: React.FC<IProps> = (props) => {
    const [isMobile, setIsMobile] = React.useState(false);
    const [editMembership, setEditMembership] = React.useState<IMembership>(props.membership);
    const [memberships, setMemberships] = React.useState<IMembership[]>([]);
    const membershipService = new MembershipService();

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, []);

    React.useEffect(() => {
        membershipService.getMemberships().then((data) => {
            setMemberships(data.filter(m => m.type === "Speler") || []);
        })
    }, [])

    const onUserChange = (value: number | string): void => {
        const membership = memberships.find(m => m.user.id === value);
        if (membership == null) {
            return;
        }

        setEditMembership(membership);
    }

    const onGoalsChange = (value: number): void => {
        const amount = value;

        /* TODO: below code was based on strings, should be removed.
        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {*/

        if (amount >= 0) {
            const membership = { ...editMembership, goals: Number(value) };
            setEditMembership(membership);
        }
    }

    const onUpdate = (): void => {
        // Save the edited membership.
        membershipService.updateMembership(editMembership.user.id, editMembership);
        props.onClose(editMembership);
    }

    return (<>
        <Box>
            <Dialog open={props.isOpen} onClose={onUpdate} sx={{
                "& .MuiDialog-container": {
                    justifyContent: "center",
                    alignItems: "flex-start",
                    marginTop: "65px"
                }
            }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ paddingBottom: 0}}>
                    <h4>Topscorer wijzigen</h4>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <FormControl fullWidth sx={{ marginTop: '5px'}}>
                        <InputLabel id="demo-simple-select-label">Speler</InputLabel>
                        <Select
                            native={false}
                            value={editMembership.user.id}
                            label="Speler"
                            placeholder="Selecteer speler"
                            onChange={(e) => onUserChange(e.target.value)}
                            MenuProps={{
                                style: {
                                    maxHeight: 400,
                                },
                            }}
                        >
                            {memberships.map(m => (
                                isMobile ? (
                                    //<option key={m.user.id} value={m.user.id}>{m.user.username}</option>
                                     <MenuItem key={m.user.id} value={m.user.id}>{m.user.username}</MenuItem>
                                ) : (
                                    <MenuItem key={m.user.id} value={m.user.id}>{m.user.username}</MenuItem>
                                )
                            ))}
                        </Select>
                    </FormControl>

                    {/*<TextField*/}
                    {/*    margin="normal"*/}
                    {/*    id="type"*/}
                    {/*    label="Type"*/}
                    {/*    type="text"*/}
                    {/*    fullWidth*/}
                    {/*    variant="standard"*/}
                    {/*    disabled={true}*/}
                    {/*    value={editMembership.type}*/}
                    {/*/>*/}
                    <TextField
                        margin="normal"
                        id="team"
                        label="Team"
                        type="text"
                        fullWidth
                        variant="standard"
                        disabled={true}
                        value={editMembership.team.name}
                    />
                   
                    <NumberInput
                        value={editMembership.goals}
                        onRemove={() => onGoalsChange(editMembership.goals - 1)}
                        onAdd={() => onGoalsChange(editMembership.goals + 1)}
                    />

                    {/*<TextField*/}
                    {/*    margin="normal"*/}
                    {/*    id="goals"*/}
                    {/*    label="Goals"*/}
                    {/*    type="text"*/}
                    {/*    fullWidth*/}
                    {/*    variant="standard"*/}
                    {/*    value={editMembership.goals}*/}
                    {/*/>*/}

                </DialogContent>
                <DialogActions>
                    <Button onClick={onUpdate}>Opslaan</Button>
                </DialogActions>
            </Dialog>
        </Box>
    </>);
}

export default EditTopscorerDialog;
