/* eslint-disable no-mixed-operators */
import * as React from 'react';
import moment from 'moment';
import "moment/locale/nl";
import { Grid, Card, Tooltip, Box, Divider, Typography, Avatar, IconButton, List, ListItem, styled, Pagination } from '@mui/material';
import { IMatch, ITeam, IMembership } from 'entities';
import MatchActions from './MatchActions';
import Loader from '../Loader';
import { ImagePath } from '../../images';

// IMAGES
import AgendaBackgroundImage from '../../images/event-agenda-bg.jpg';

// ICONS
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const BoxWrapper = styled(Box)(
    ({ theme }) => `
      background-image: url(${AgendaBackgroundImage});
      background-size: cover;
      border-radius: ${theme.general.borderRadius};
      text-align: center;
      width: ${theme.spacing(9)};
      height: ${theme.spacing(9)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing(1.5)};
      flex-shrink: 0;
      transition: ${theme.transitions.create(['background'])};
      pointer-events: none;
`
);

const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
      border-radius: 0;
      padding: ${theme.spacing(2)};
`
);

interface IProps {
    userId: number;
    title: string;
    limit: number;
    membership: IMembership;
    matches: IMatch[];
    showMatchDetails: (match: IMatch) => void;
    updateMatch: (match: IMatch) => void;
    isLoading?: boolean;
}

const getMonthName = (month: number): string => {
    switch (month) {
        case 0:
            return "Jan"
        case 1:
            return "Feb"
        case 2:
            return "Mar"
        case 3:
            return "Apr"
        case 4:
            return "Mei"
        case 5:
            return "Jun"
        case 6:
            return "Jul"
        case 7:
            return "Aug"
        case 8:
            return "Sep"
        case 9:
            return "Okt"
        case 10:
            return "Nov"
        case 11:
            return "Dec"
        default:
            return "Onbekend";
    }
}

const renderTeam = (team: ITeam) => {
    return (<><Avatar
        src={ImagePath(team.logoUrl)}
        sx={{ width: 34, height: 34, display: 'inline-block;', marginRight: '5px', verticalAlign: 'middle' }}
    />
        <b style={{ fontSize: '14px', textAlign: 'center' }}>{team.name}</b>
    </>);
}

const Matches: React.FC<IProps> = (props) => {
    const [isMobile, setIsMobile] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1);

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);
    const matches = props.matches.slice(0, props.limit);

    const itemsPerPage = 2;
    const maxPageCount = Math.ceil(matches.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageMatches = matches.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Card>
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <DateRangeTwoToneIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">{props.title}</Typography>
                </Box>
                <Tooltip placement="top" arrow title="Create new event">
                    <IconButton sx={{ alignSelf: 'center' }} color="primary"
                    >
                        <ControlPointTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            {props.isLoading && (<>
                <Loader />
            </>) || matches.length <= 0 && (
                <Typography sx={{ py: 5 }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >
                    {"We hebben geen wedstrijden gevonden"}
                </Typography>
            ) || (<>
                <List disablePadding component="div">
                    {pageMatches.map(match => (<div key={match.matchDate.toString()}>
                        <ListItemWrapper alignItems="flex-start" style={{ marginBottom: (isMobile ? 0 : '25px'), paddingBottom: 0 }}>
                            <BoxWrapper className="MuiDate-wrapper">
                                <Box>
                                    <Typography variant="h2" color="text.primary" sx={{ color: 'white'}}>
                                        {new Date(match.matchDate).getDate()}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>{getMonthName(new Date(match.matchDate).getMonth())}</Typography>
                                </Box>
                            </BoxWrapper>
                            <Box width="100%">
                                <Grid container>
                                    <Grid item xs={12} md="auto" sx={{ marginRight: '5px' }}>
                                        {renderTeam(match.team)}
                                    </Grid>
                                    <Grid item xs={1} md={1} sx={{ display: { lg: 'flex', xs: 'none' }, alignItems: 'flex-start', justifyContent: 'center' }} >
                                        <b>-</b>
                                    </Grid>
                                    <Grid item xs={12} md="auto">
                                        {renderTeam(match.opponentTeam)}
                                    </Grid>
                                </Grid>
                                {!isMobile && (
                                    <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
                                        <div><b>Wanneer: </b>
                                            {moment(match.matchDate).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')) && (<>
                                                {moment(match.matchDate).fromNow()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </>) || (<>
                                                {new Date(match.matchDate).toLocaleDateString()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </>)}
                                        </div>
                                        <div><b>Adres:</b> {match.location}</div>
                                        <div><b>Aanwezig:</b> {match.enrollments.filter(e => e.status === "Aanwezig" && e.membership.type === "Speler").length} spelers(s)</div>
                                    </Typography>
                                )}
                            </Box>
                        </ListItemWrapper>
                        {isMobile && (
                            <Box sx={{
                                padding: 2,
                                paddingTop: 0,
                                marginLeft: '5px'
                            }}>
                                <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
                                    <div><b>Wanneer: </b>
                                        {moment(match.matchDate).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')) && (<>
                                            {moment(match.matchDate).fromNow()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>) || (<>
                                            {new Date(match.matchDate).toLocaleDateString()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>)}
                                    </div>
                                    <div><b>Adres:</b> {match.location}</div>
                                    <div><b>Aanwezig:</b> {match.enrollments.filter(e => e.status === "Aanwezig" && e.membership.type === "Speler").length} spelers(s)</div>
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{
                            padding: 2,
                            paddingTop: 0
                        }}>
                            <MatchActions match={match} userId={props.userId} membershipId={props.membership.id} updateMatch={props.updateMatch} showMatchDetails={() => props.showMatchDetails(match)} />
                        </Box>
                        <Divider />
                    </div>))}
                </List>
                {maxPageCount > 1 && (
                    <Pagination sx={{ padding: '15px' }}
                        count={maxPageCount} color="primary"
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value)}
                    />
                )}
            </>)}
        </Card>
    );
}

export default Matches;
