import * as React from 'react';
import { useRoutes } from 'react-router-dom';
import router from './router';

import ThemeProvider from './theme/ThemeProvider';
import { useAuth } from './contexts/AuthContext';

import { CssBaseline } from '@mui/material';
import SuspenseLoader from './components/SuspenseLoader';

function App() {
    const content = useRoutes(router);
    const { isLoadingAuth } = useAuth();

    return (
        <ThemeProvider>
            <CssBaseline />
            {isLoadingAuth ? <SuspenseLoader /> : content}
        </ThemeProvider>
    );
}
export default App;