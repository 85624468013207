import * as React from 'react';
import { SidebarContext } from 'contexts/SidebarContext';
import { Box, Drawer, styled, Divider, useTheme, darken } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import Scrollbar from 'components/ScrollBar';
import SidebarMenu from './SidebarMenu';

// IMAGES
import SidebarBackgroundImage from 'images/layout/sidebar-banner-blue.png';

// ICONS
import EuroSymbol from '@mui/icons-material/EuroSymbol';

const SidebarHeaderWrapper = styled(Box)(
    ({ theme }) => `
            height: 250px;
            margin-top: 0px;
            margin-bottom: -60px;
            padding: 1px 1px 20px;
            color: ${theme.colors.alpha.trueWhite[100]};
            background-image: url(${SidebarBackgroundImage});
        `
    );

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.secondary.main};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

function Sidebar() {
    const { sidebarToggle, toggleSidebar } = React.useContext(SidebarContext);
    const closeSidebar = () => toggleSidebar();
    const theme = useTheme();
    const { user, membership, loadMembership, isLoadingMembership } = useAuth();

    React.useEffect(() => {
        if (user?.isActive && membership == null && !isLoadingMembership) {
            loadMembership();
        }
    }, [])

    return (
        <>
            <SidebarWrapper
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'inline-block'
                    },
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    background:
                        theme.palette.mode === 'dark'
                            ? darken(theme.colors.alpha.black[100], 0.5)
                            : theme.sidebar.background,
                    boxShadow:
                        theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
                }}
            >
                <Scrollbar>
                    <SidebarHeaderWrapper mt={0}>
                        <Box mx={2} sx={{ width: 250 }}                        >
                            <div style={{ display: 'flex', margin: '15px 0' }}>
                                <picture>
                                    <img alt="mimaveva-logo" height="50px" src="https://mimavevablob.blob.core.windows.net/mimaveva/logo-mimaveva-white.png" />
                                </picture>
                            </div>
                            <Divider
                                sx={{
                                    mt: theme.spacing(1),
                                    mx: theme.spacing(2),
                                    background: theme.colors.alpha.trueWhite[10]
                                }}
                            />
                            {user != null && (<>
                                <h2 style={{ marginBottom: "0"}}>{user.username}</h2>
                                <div><b>Lidmaatschap:</b> {membership != null ? membership.type : "Geen"}</div>
                                <div><b>Email:</b> {user.email}</div>
                                <div style={{ fontWeight: 'bold' }}>Openstaand bedrag: <EuroSymbol style={{ fontSize: '14px', position: 'relative', top: '1.5px' }} />{membership ? membership.amountDue : 0}</div>
                            </>)}
                        </Box>
                    </SidebarHeaderWrapper>
                    <Divider
                        sx={{
                            mt: theme.spacing(3),
                            mx: theme.spacing(2),
                            background: theme.colors.alpha.trueWhite[10]
                        }}
                    />
                    <SidebarMenu />
                </Scrollbar>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`
                }}
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.white[100]
                                : theme.sidebar.background
                    }}
                >
                    <Scrollbar>
                        <SidebarHeaderWrapper mt={3}>
                            <Box mx={2} sx={{ width: '80%' }}                            >
                                <div style={{ display: 'flex', margin: '15px 0' }}>
                                    <picture>
                                        <img alt="mimaveva-logo" height="50px" src="https://mimavevablob.blob.core.windows.net/mimaveva/logo-mimaveva-white.png" />
                                    </picture>
                                </div>
                                <Divider
                                    sx={{
                                        background: theme.colors.alpha.trueWhite[10]
                                    }}
                                />
                                {user != null && (<>
                                    <h2 style={{ marginBottom: "0", color: 'white' }}>{user.username}</h2>
                                    <div><b>Lidmaatschap:</b> {membership != null ? membership.type : "Geen"}</div>
                                    <div><b>Email:</b> {user.email}</div>
                                    <div style={{ color: 'white', fontWeight: 'bold' }}>
                                        Openstaand bedrag: <EuroSymbol style={{ fontSize: '14px', position: 'relative', top: '1.5px' }} />{membership ? membership.amountDue : 0}
                                    </div>
                                </>)}
                            </Box>
                        </SidebarHeaderWrapper>
                        <Divider
                            sx={{
                                mt: theme.spacing(3),
                                mx: theme.spacing(2),
                                background: theme.colors.alpha.trueWhite[10]
                            }}
                        />
                        <SidebarMenu />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
}

export default Sidebar;
