import * as  React from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { alpha, Badge, Box, Divider, IconButton, List, ListItem, Popover, Tooltip, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { useAuth } from 'contexts/AuthContext';
import { NotificationService } from 'services/notificationService';
import { INotification, Mail } from 'entities';

// ICONS
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';

const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.colors.alpha.white[100]};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);
const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
      &:hover {
            background: ${alpha(theme.colors.alpha.black[100], 0.08)};
      }
`
);

function HeaderNotifications() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [notifications, setNotifications] = React.useState<INotification[]>([]);

    const notificationService = new NotificationService();

    React.useEffect(() => {
        if (user == null || user == undefined) {
            return;
        }

        notificationService.getNotificationsByUser(user.id).then((notificationList) => {
            if (notificationList == null) {
                return
            }
            setNotifications(notificationList);
        });
    }, [user]);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const onItemClick = (notificationGuid: string): void => {
        // Update the current notifications list.
        const index = notifications.findIndex(n => n.guid === notificationGuid);
        const notificationsCopy = [...notifications];
        notificationsCopy[index].isRead = true;
        setNotifications(notificationsCopy);

        // Navigate to the notification message.
        handleClose();
        navigate(`/notifications/${notificationGuid}/users/${user?.id}/details`)
    }

    const isNotificationsAvailable = React.useMemo(() => notifications.length > 0, [notifications]);

    return (
        <>
            <Tooltip arrow title="Notifications">
                <IconButton color="primary" ref={ref} onClick={handleOpen} sx={{ color: "white" }}>
                    <NotificationsBadge
                        badgeContent={notifications.filter(n => !n.isRead).length}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <NotificationsActiveTwoToneIcon />
                    </NotificationsBadge>
                </IconButton>
            </Tooltip>
            <Popover
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box
                    sx={{ p: 2 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h5">Notificaties</Typography>
                </Box>
                <Divider />
                <List sx={{ p: 0 }}>
                    {!isNotificationsAvailable && (
                        <ListItem
                            sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }}
                        >
                            <Box flex="1">
                                <Box display="flex" justifyContent="space-between">
                                    <Typography sx={{ fontWeight: 'bold' }}>
                                        Messaging Platform
                                    </Typography>
                                    <Typography variant="caption" sx={{ textTransform: 'none' }}>
                                    </Typography>
                                </Box>
                                <Typography
                                    component="span"
                                    variant="body2"
                                    color="text.secondary"
                                >
                                    {' '}
                                    Geen nieuwe berichten in de inbox
                                </Typography>
                            </Box>
                        </ListItem>
                    ) || isNotificationsAvailable && (
                        notifications.map((notification) =>
                            <ListItemWrapper key={notification.id} sx={{ p: 2, minWidth: 350, display: { xs: 'block', sm: 'flex' } }} onClick={() => onItemClick(notification.guid)}>
                                <Box display="flex" flex="1">
                                    <Box sx={{ display: "grid !important", alignContent: "center", marginRight: "10px" }}>
                                        {notification.isRead ? <NotificationsActiveTwoToneIcon /> : <NotificationsActiveIcon color="warning" />}
                                    </Box>
                                    <Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography sx={{ fontWeight: 'bold' }}>
                                                {notification.subject}
                                            </Typography>
                                            <Typography variant="caption" sx={{ textTransform: 'none' }}>
                                            </Typography>
                                        </Box>
                                        <Typography
                                            component="span"
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {' '}
                                            {moment(notification.createdDate).fromNow()}
                                        </Typography>
                                    </Box>
                                </Box>
                            </ListItemWrapper>
                        )
                    )}
                </List>
            </Popover>
        </>
    );
}

export default HeaderNotifications;
