/* eslint-disable no-mixed-operators */
import * as React from 'react';
import moment from 'moment';
import "moment/locale/nl";
import { Grid, Card, Tooltip, Box, Divider, Typography, Avatar, IconButton, List, ListItem, styled, Pagination } from '@mui/material';
import { IMatch, IMembership, IEvent } from 'entities';
import Loader from '../Loader';

// ICONS
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';
import EventActions from './EventActions';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const BoxWrapper = styled(Box)(
    ({ theme }) => `
      background-image: url(https://images.pexels.com/photos/114979/pexels-photo-114979.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500);
      background-size: cover;
      border-radius: ${theme.general.borderRadius};
      text-align: center;
      width: ${theme.spacing(9)};
      height: ${theme.spacing(9)};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: ${theme.spacing(1.5)};
      flex-shrink: 0;
      transition: ${theme.transitions.create(['background'])};
      pointer-events: none;
`
);

const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
      border-radius: 0;
      padding: ${theme.spacing(2)};
`
);

interface IProps {
    userId: number;
    title: string;
    limit: number;
    membership: IMembership;
    events: IEvent[];
    showEventDetails: (event: IEvent) => void;
    updateEvent: (event: IMatch) => void;
    isLoading?: boolean;
}

const getMonthName = (month: number): string => {
    switch (month) {
        case 0:
            return "Jan"
        case 1:
            return "Feb"
        case 2:
            return "Mar"
        case 3:
            return "Apr"
        case 4:
            return "Mei"
        case 5:
            return "Jun"
        case 6:
            return "Jul"
        case 7:
            return "Aug"
        case 8:
            return "Sep"
        case 9:
            return "Okt"
        case 10:
            return "Nov"
        case 11:
            return "Dec"
        default:
            return "Onbekend";
    }
}

const Events: React.FC<IProps> = (props) => {
    const [isMobile, setIsMobile] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1);

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);
    const allEvents = props.events.sort((a, b) => moment(a.eventDate).diff(moment(b.eventDate))).reverse(); //.filter(e => e.id !== 1 && e.id !== 3);
    const events = allEvents.slice(0, props.limit);

    const itemsPerPage = 1;
    const maxPageCount = Math.ceil(events.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageEvents = events.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Card>
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <DateRangeTwoToneIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">{props.title}</Typography>
                </Box>
                <Tooltip placement="top" arrow title="Create new event">
                    <IconButton sx={{ alignSelf: 'center' }} color="primary"
                    >
                        <ControlPointTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            {props.isLoading && (<>
                <Loader />
            </>) || events.length <= 0 && (
                <Typography sx={{ py: 5 }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >
                    {"We hebben geen evenementen gevonden"}
                </Typography>
            ) || (<>
                <List disablePadding component="div">
                    {pageEvents.map(event => (<div key={event.eventDate.toString()}>
                        <ListItemWrapper alignItems="flex-start" style={{ marginBottom: (isMobile ? 0 : '25px'), paddingBottom: 0 }}>
                            <BoxWrapper className="MuiDate-wrapper">
                                <Box>
                                    <Typography variant="h2" color="text.primary" sx={{ color: 'white'}}>
                                        {new Date(event.eventDate).getDate()}
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: 'white', fontWeight: 'bold' }}>{getMonthName(new Date(event.eventDate).getMonth())}</Typography>
                                </Box>
                            </BoxWrapper>
                            <Box width="100%">
                                <Grid container>
                                    <h2 style={{ margin: 0 }}>{event.title}</h2>
                                </Grid>
                                {!isMobile && (
                                    <Typography variant="body1" color="text.secondary">
                                        <div><b>Wanneer: </b>
                                            {moment(event.eventDate).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')) && (<>
                                                {moment(event.eventDate).fromNow()} om {new Date(event.eventDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </>) || (<>
                                                {new Date(event.eventDate).toLocaleDateString()} om {new Date(event.eventDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </>)}
                                        </div>
                                        <div><b>Adres:</b> {event.location}</div>
                                        {(event.id === 3) && (
                                            <div><b>Kosten:</b> €15 p.p</div>
                                        )}
                                        {(event.id === 9) && (
                                            <div><b>Kosten:</b> €15 voor partner</div>
                                        )}
                                    </Typography>
                                )}
                            </Box>
                        </ListItemWrapper>
                        {isMobile && (
                            <Box sx={{
                                padding: 2,
                                paddingTop: 0,
                                marginLeft: '5px'
                            }}>
                                <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
                                    <div><b>Wanneer: </b>
                                        {moment(event.eventDate).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')) && (<>
                                            {moment(event.eventDate).fromNow()} om {new Date(event.eventDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>) || (<>
                                            {new Date(event.eventDate).toLocaleDateString()} om {new Date(event.eventDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </>)}
                                    </div>
                                    <div><b>Adres:</b> {event.location}</div>
                                     {(event.id === 3) && (
                                            <div><b>Kosten:</b> €15 p.p</div>
                                        )}
                                        {(event.id === 9) && (
                                            <div><b>Kosten:</b> €15 voor partner</div>
                                        )}
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{
                            padding: 2,
                            paddingTop: 0
                        }}>
                            <EventActions event={event} userId={props.userId} membershipId={props.membership.id} updateMatch={props.updateEvent} showDetails={() => props.showEventDetails(event)} />
                        </Box>
                        <Divider />
                    </div>))}
                </List>
                {maxPageCount > 1 && (
                    <Pagination sx={{ padding: '15px' }}
                        count={maxPageCount} color="primary"
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value)}
                    />
                )}
            </>)}
        </Card>
    );
}

export default Events;
