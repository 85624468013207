import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Card, Divider, Typography, styled, Avatar, Grid, ListItem, ListItemAvatar, Link, ListItemText } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { UserService } from 'services/userService';
import { IMembership, IUser } from 'entities';
import Text from 'components/Text';
import BudgetWidget from './BugetWidget';

// ICONS
import PaymentIcon from '@mui/icons-material/Payment';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import styles from 'styles/modules/app.module.scss';
import { MembershipService } from '../../../services/membershipService';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

interface IFinanceUser {
    user: IUser;
    membership: IMembership;
}

const ManageFinance: React.FC = () => {
    const navigate = useNavigate();
    const { membership, loadMembership } = useAuth();
    const [users, setUsers] = React.useState<IUser[]>([]);
    const [financeUsers, setFinanceUsers] = React.useState<IFinanceUser[]>([]);

    React.useEffect(() => {
        if (membership == null) {
            loadMembership();
        }
    }, [membership, loadMembership])


    React.useEffect(() => {
        const userService = new UserService();
        const membershipService = new MembershipService();

        // Read user details with memberships.
        let userFinanceList: IFinanceUser[] = [];
        userService.getUsers().then(data => {
            data?.forEach((u) => {
                membershipService.getMembershipByUser(u.id).then(m => {
                    if (m == null) {
                        return;
                    }
                    const entity: IFinanceUser = { user: u, membership: m };
                    userFinanceList = [...userFinanceList, entity];
                    setFinanceUsers(userFinanceList || []);
                });
            });

            setUsers(data || []);
        });
    }, [])

    const financePendingUsersSaldo = React.useMemo(() => 
         financeUsers.filter(u => u.membership.amountDue > 0).filter(u => u.user.isActive)
    , [financeUsers]);

    const financeUsersSaldo = React.useMemo(() =>
        financeUsers.filter(u => financePendingUsersSaldo.every(fu => fu.user.id != u.user.id)).filter(u => u.user.isActive)
    , [financePendingUsersSaldo, financeUsers]);

    return (
        <Box className={styles.overview}>
            <Helmet>
                <title>Financieel overzicht</title>
            </Helmet>
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                    marginBottom={5}
                >
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <Box
                                p={2}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    <AvatarPrimary sx={{ mr: 1.5 }}>
                                        <PaymentIcon />
                                    </AvatarPrimary>
                                    <Typography variant="h4">FINANCIEEL OVERZICHT</Typography>
                                </Box>
                            </Box>
                            <BudgetWidget pendingAmount={financePendingUsersSaldo.filter(item => item.membership.amountDue > 0)
                                .reduce((sum, current) => sum + current.membership.amountDue, 0)} />
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card variant="outlined">
                            <Box
                                p={2}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    <AvatarPrimary sx={{ mr: 1.5 }}>
                                        <PaymentIcon />
                                    </AvatarPrimary>
                                    <Typography variant="h4">NOG NIET BETAALD</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            {financePendingUsersSaldo.map(data => (<div key={data.user.username}>
                                <ListItem
                                    key={data.user.id}
                                    sx={{ px: 2, py: 1.95 }}>
                                    <ListItemAvatar
                                        sx={{ mr: 2, minWidth: 0 }}>
                                        <Avatar src={data.user.avatar} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<>
                                            <Link
                                                onClick={() => data.user.id == 0 ? navigate(`#`) : navigate(`/manage/users/${data.user.guid}/details`)}
                                                color="text.primary"
                                                variant="h5"
                                            >
                                                {data.user.username}
                                            </Link>
                                        </>}
                                        secondary={<>{data.user.email}</>}
                                    />
                                    <Text color="error">
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography variant="body1" fontWeight="bold">
                                                € {data.membership.amountDue}
                                            </Typography>
                                            <ArrowDownwardIcon fontSize="small" />
                                        </Box>
                                    </Text>
                                </ListItem>
                                <Divider />
                            </div>))}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <Box
                                p={2}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    <AvatarPrimary sx={{ mr: 1.5 }}>
                                        <PaymentIcon />
                                    </AvatarPrimary>
                                    <Typography variant="h4">BETAALD</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            {financeUsersSaldo.map(data => (<div key={data.user.name}>
                                <ListItem
                                    key={data.user.id}
                                    sx={{ px: 2, py: 1.95 }}>
                                    <ListItemAvatar
                                        sx={{ mr: 2, minWidth: 0 }}>
                                        <Avatar src={data.user.avatar} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<>
                                            <Link
                                                onClick={() => navigate(`/manage/users/${data.user.guid}/details`)}
                                                color="text.primary"
                                                variant="h5"
                                            >
                                                {data.user.username}
                                            </Link>
                                        </>
                                        }
                                        secondary={<>{data.user.email}</>}
                                    />
                                    <Text color="success">
                                        <Box display="flex" alignItems="center" justifyContent="center">
                                            <Typography variant="body1" fontWeight="bold">
                                                € {data.membership.amountDue},-
                                            </Typography>
                                        </Box>
                                    </Text>
                                </ListItem>
                                <Divider />
                            </div>))}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ManageFinance;