import { INotification, Mail } from 'entities/index';
const servicePath = "/notification";

export class NotificationService {
    public getNotification = async (notificationId: string, userId?: string): Promise<Mail | null> => {
        return await userId != null ? get(`/${notificationId}/users/${userId}`) : get(`/${notificationId}`);
    }

    public getNotificationsByUser = async (userId: number): Promise<INotification[] | null> => {
        return await get(`/user/${userId}`);
    }
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params))

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}