/* eslint-disable no-mixed-operators */
import * as React from 'react';
import Geocode from "react-geocode";
import { Coords } from 'google-map-react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, CardHeader, Card, List, ListItemAvatar, ListItem, ListItemText, Avatar,
    Link, Typography, Divider, useTheme, FormControl, FormGroup, FormControlLabel, Checkbox, Alert, Grid,
} from '@mui/material';
import { IEvent, IEventEnrollment } from 'entities';
import Text from 'components/Text';

// ICONS
import LabelIcon from '@mui/icons-material/Label';

interface IProps {
    event: IEvent;
    enrollments: IEventEnrollment[];
}

const EventEnrollments: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const event = props.event;

    const [isAanwezigFilter, setIsAanwezigFilter] = React.useState<boolean>(false);
    const [isAfwezigFilter, setIsAfwezigFilter] = React.useState<boolean>(false);
    const [filteredEnrollments, setFilteredEnrollments] = React.useState<IEventEnrollment[]>(props.enrollments)
    const [eventLocation, setMatchLocation] = React.useState<Coords | null>(null);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    React.useEffect(() => {
        if (!isAanwezigFilter && !isAfwezigFilter) {
            const sortedEnrollments = [...props.enrollments].sort((a, b) => a.user.username.localeCompare(b.user.username));
            setFilteredEnrollments(sortedEnrollments);
            return;
        }

        let enrollments: IEventEnrollment[] = [];
        if (isAanwezigFilter) {
            enrollments = props.enrollments.filter(e => e.status.toLocaleLowerCase() === "aanwezig")
        }
        if (isAfwezigFilter) {
            enrollments = enrollments.concat(props.enrollments.filter(e => e.status.toLocaleLowerCase() === "afwezig"));
        }
        if (isAfwezigFilter) {
            enrollments = enrollments.concat(props.enrollments.filter(e => e.status.toLocaleLowerCase() === "rustend lid"));
        }

        const sortedEnrollments = [...enrollments].sort((a, b) => a.user.username.localeCompare(b.user.username));
        setFilteredEnrollments(sortedEnrollments);
    }, [props.enrollments, isAanwezigFilter, isAfwezigFilter])


    React.useEffect(() => {
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey("AIzaSyB1fbHrux9w5e0jbDUifWalk_JvjvzoOUM");

        // Get latitude & longitude from address.
        Geocode.fromAddress(event.location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setMatchLocation({
                    lat, lng
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, [event.location]);

    const readStatusText = (enrollment: IEventEnrollment): JSX.Element => {
        let status = enrollment.status.toUpperCase();
        if (enrollment.isAttendingPartner) {
            status += " met partner";
        }

        return <Text color={enrollment.status.toLowerCase() === "aanwezig" ? "success" : "error"}>{status.toUpperCase()}</Text>;
    }

    const readStatusColor = (status: string) => (status === "aanwezig") ? theme.colors.success.main : theme.colors.error.main;
    const googleLink = event.location != null ? `https://www.google.com/maps/search/?api=1&query=${event.location}` : "";
    return (
        <Card variant="outlined">
            <CardHeader
                sx={{
                    p: 3
                }}
                disableTypography
                title={
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: `${theme.typography.pxToRem(16)}`
                        }}
                    >
                        <Box width="100%">
                            <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '80%', textAlign: 'center' }}>
                                    <h2>{event.title}</h2>
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography variant="body1" color="text.primary">
                            <div>{event.description}</div>
                        </Typography>
                        <Typography variant="body1" color="text.secondary" sx={{ marginTop: '5px'}}>
                            <div><b>Datum:</b> {new Date(event.eventDate).toLocaleDateString()} om {new Date(event.eventDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            <div><b>Adres:</b> <a href={googleLink}>{event.location}</a></div>
                            <div><b>Aanwezig:</b> {event.enrollments.filter(e => e.status === "Aanwezig").length} leden</div>
                        </Typography>
                    </Typography>
                }
            />
            {eventLocation != null && event != null && (<>
                <LoadScript
                    googleMapsApiKey="AIzaSyDISt9DVtpHshhlXYGbAxnHmZaTbJHDhdY"
                >
                    <GoogleMap
                        mapContainerStyle={{
                            height: "200px",
                            width: "100%"
                        }}
                        center={eventLocation}
                        zoom={15}
                    >
                        <Marker position={eventLocation} />
                    </GoogleMap>
                </LoadScript>

                <Button href={googleLink}>Toon Route</Button>
            </>)}

            <Divider />
            <Box sx={{ padding: 2 }}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel color="success"
                            control={<Checkbox color="success"
                                onClick={() => setIsAanwezigFilter(!isAanwezigFilter)} />}
                            label={<Text><b>AANWEZIG</b></Text>}
                        />
                        <FormControlLabel color="error"
                            control={<Checkbox color="error"
                                onClick={() => setIsAfwezigFilter(!isAfwezigFilter)} />}
                            label={<Text><b>AFWEZIG</b></Text>}
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <div id="list-container">
            <List sx={{ py: 0, display: "flex", flexWrap: "wrap" }}>
                {(filteredEnrollments.length <= 0) && (
                    <Box sx={{ margin: 2 }}>
                        <Alert severity="info">Geen leden gevonden</Alert>
                    </Box>
                ) || filteredEnrollments.map(enrollment => (
                    <div key={enrollment.user.id}>
                        <ListItem key={enrollment.user.id} sx={{ px: 2, py: 1.95, width: "300px" }}>
                            <LabelIcon
                                fontSize="medium"
                                sx={{ color: `${readStatusColor(enrollment.status.toLowerCase())}`, marginRight: '5px' }}
                            />
                            <ListItemAvatar sx={{ mr: 2, minWidth: 0 }}>
                                <Avatar src={enrollment.user.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Link
                                        onClick={() => navigate(`/manage/users/${enrollment.user.guid}/details`)}
                                        color="text.primary" variant="h5">
                                        {enrollment.user.username}
                                    </Link>
                                }
                                secondary={
                                    <Typography variant="subtitle2" noWrap>
                                        <Typography sx={{ pr: 1 }} variant="h5" >
                                           {readStatusText(enrollment)}
                                        </Typography>
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </div>
                ))}
                </List>
            </div>
        </Card>
    );
}

export default EventEnrollments;
