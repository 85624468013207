import { Box, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';

// ICONS
import HomeIcon from '@mui/icons-material/Home';

function HeaderButtons() {
    const navigate = useNavigate();

  return (
      <Box sx={{ mr: 0 }} >
          <Tooltip arrow title="Search">
              <IconButton sx={{ color: "white" }} color="primary" onClick={() => navigate('/overview')}>
                  <HomeIcon />
              </IconButton>
          </Tooltip>
          <Box sx={{ mx: 0.5 }} component="span">
        <HeaderNotifications />
      </Box>
    </Box>
  );
}

export default HeaderButtons;
