import { IUser } from 'entities/index';
const servicePath = "/user";

export class UserService {

    public getUsers = async (): Promise<IUser[] | null> => {
        return await get();
    }

    public getUser = async (userId: string): Promise<IUser | null> => {
        return await get("/details", {
            userId,
        });
    }

    public updateUser = async (userId: string, user: IUser): Promise<IUser | null> => {
        const url = servicePath + '/details';
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userGuid": userId,
                "username": user.username,
                "address": user.address,
                "dateOfBirth": user.dateOfBirth ? new Date(user.dateOfBirth).toISOString() : null
            })
        });
        return await response.json();
    }

    public updateUserAvatar = async (userId: string, profileImageUrl: string): Promise<IUser | null> => {
        const url = servicePath + '/details';
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "userGuid": userId,
                "profileImageUrl": profileImageUrl,
            })
        });
        return await response.json();
    }
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params))

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}