import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import StadiumIcon from '@mui/icons-material/Stadium';
import Typography from '@mui/material/Typography';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import moment from 'moment';

const AccountableTimeline: React.FC = () => {
    interface Row {
        type: string;
        name: string;
        date: string;
    }

    const data: Row[] = [
        { type: 'BEKER', name: 'Tom', date: '1-9-2024' },
        { type: 'BEKER', name: 'Bram', date: '8-9-2024' },
        { type: 'BEKER', name: 'David', date: '15-9-2024' },
        { type: 'COMPETITIE', name: 'Eddy', date: '22-09-2024' },
        { type: 'COMPETITIE', name: 'Mark', date: '29-9-2024' },
        { type: 'COMPETITIE', name: 'Mickey', date: '6-10-2024' },
        { type: 'COMPETITIE', name: 'Niels', date: '13-10-2024' },
        { type: 'COMPETITIE', name: 'Richard', date: '20-10-2024' },
        { type: 'BEKER / INHAAL', name: 'Roberto', date: '27-10-2024' },
        { type: 'COMPETITIE', name: 'Roy Horsten', date: '3-11-2024' },
        { type: 'COMPETITIE', name: 'Selcuk', date: '10-11-2024' },
        { type: 'COMPETITIE', name: 'Sief', date: '17-11-2024' },
        { type: 'BEKER / INHAAL', name: 'Sjors', date: '24-11-2024' },
        { type: 'COMPETITIE', name: 'Steven', date: '1-12-2024' },
        { type: 'COMPETITIE', name: 'Sven', date: '8-12-2024' },
        { type: 'BEKER / INHAAL', name: 'Thomas', date: '15-12-2024' },
        { type: 'BEKER / INHAAL', name: 'Tom', date: '12-1-2025' },
        { type: 'BEKER / INHAAL', name: 'Alcide', date: '19-1-2025' },
        { type: 'COMPETITIE', name: 'William', date: '26-1-2025' },
        { type: 'COMPETITIE', name: 'William de Jong', date: '2-2-2025' },
        { type: 'COMPETITIE', name: 'Yordi', date: '9-2-2025' },
        { type: 'COMPETITIE', name: 'Alcide', date: '16-2-2025' },
        { type: 'COMPETITIE', name: 'Bram', date: '23-2-2025' },
        { type: 'BEKER / INHAAL', name: 'David', date: '9-3-2025' },
        { type: 'COMPETITIE', name: 'Eddy', date: '16-3-2025' },
        { type: 'COMPETITIE', name: 'Mark', date: '23-3-2025' },
        { type: 'BEKER / INHAAL', name: 'Mickey', date: '30-3-2025' },
        { type: 'COMPETITIE', name: 'Niels', date: '6-4-2025' },
        { type: 'COMPETITIE', name: 'Richard', date: '13-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Roberto', date: '19-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Roy Horsten', date: '21-4-2025' },
        { type: 'BEKER / INHAAL', name: 'Selcuk', date: '27-4-2025' },
        { type: 'COMPETITIE', name: 'Sief', date: '4-5-2025' },
        { type: 'COMPETITIE', name: 'Sjors', date: '11-5-2025' },
        { type: 'COMPETITIE', name: 'Steven', date: '18-5-2025' },
        { type: 'BEKER / INHAAL', name: 'Sven', date: '25-5-2025' },
        { type: 'BEKER / INHAAL', name: 'Thomas', date: '1-6-2025' },
        { type: 'BEKER / INHAAL', name: 'Mirko', date: '7-6-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '1-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Niels', date: '8-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Richard', date: '15-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Roberto', date: '22-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Roy Horsten', date: '29-9-2024' },
        { type: 'BIDONS / BALLEN', name: 'Selcuk', date: '6-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sief', date: '13-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sjors', date: '20-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Steven', date: '27-10-2024' },
        { type: 'BIDONS / BALLEN', name: 'Sven', date: '3-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Thomas', date: '10-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Alcide', date: '17-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'Bram', date: '24-11-2024' },
        { type: 'BIDONS / BALLEN', name: 'David', date: '1-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '8-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Mark', date: '15-12-2024' },
        { type: 'BIDONS / BALLEN', name: 'Mickey', date: '12-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '19-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Niels', date: '26-1-2025' },
        { type: 'BIDONS / BALLEN', name: 'Richard', date: '2-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Roberto', date: '9-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Roy Horsten', date: '16-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Selcuk', date: '23-2-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sief', date: '9-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sjors', date: '16-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Steven', date: '23-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Sven', date: '30-3-2025' },
        { type: 'BIDONS / BALLEN', name: 'Thomas', date: '6-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Tom', date: '13-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'William', date: '19-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'William de Jong', date: '21-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Yordi', date: '27-4-2025' },
        { type: 'BIDONS / BALLEN', name: 'Alcide', date: '4-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Bram', date: '11-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'David', date: '18-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Eddy', date: '25-5-2025' },
        { type: 'BIDONS / BALLEN', name: 'Mark', date: '1-6-2025' },
        { type: 'BIDONS / BALLEN', name: 'Mickey', date: '7-6-2025' },
    ];

    data.sort((a, b) => {
        const dateA = new Date(a.date.split(/[-/ ]+/).reverse().join('-')).getTime();
        const dateB = new Date(b.date.split(/[-/ ]+/).reverse().join('-')).getTime();
        return dateA - dateB;
    });

    return (
        <Timeline position="right" nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
            {data.map(item => (
                <TimelineItem>
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                    >
                        {item.type}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        {item.type === "BEKER" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "BEKER / INHAAL" && (
                            <TimelineDot color="warning" sx={{ marginLeft: '10px' }}>
                                <EmojiEventsIcon />
                            </TimelineDot>
                        )}
                        {item.type === "COMPETITIE" && (
                            <TimelineDot color="primary" sx={{ marginLeft: '10px' }}>
                                <StadiumIcon />
                            </TimelineDot>
                        )}
                        {item.type === "BIDONS / BALLEN" && (
                            <TimelineDot color="success" sx={{ marginLeft: '10px' }}>
                                <SportsSoccerIcon />
                            </TimelineDot>
                        )}
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                        <Typography variant="h6" component="span">
                            {item.name}
                        </Typography>
                        <Typography>{moment(item.date, 'DD-MM-YYYY').format("LL")}</Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
}

export default AccountableTimeline;