/* eslint-disable no-mixed-operators */
import * as React from 'react';
import Geocode from "react-geocode";
import { Coords } from 'google-map-react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
import {
    Box, Button, CardHeader, Card, List, ListItemAvatar, ListItem, ListItemText,
    Avatar, Link, Typography, Divider, useTheme, FormControl, FormGroup, FormControlLabel, Checkbox, Alert, Grid
} from '@mui/material';
import { IMatchEnrollment, IMatch, ITeam } from 'entities';
import Text from 'components/Text';

// ICONS
import LabelIcon from '@mui/icons-material/Label';
import { ImagePath } from '../../images';
import moment from 'moment';

interface IProps {
    match: IMatch;
    enrollments: IMatchEnrollment[];
}

const MatchEnrollments: React.FC<IProps> = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const match = props.match;

    const [isAanwezigFilter, setIsAanwezigFilter] = React.useState<boolean>(false);
    const [isAfwezigFilter, setIsAfwezigFilter] = React.useState<boolean>(false);
    const [isRustendLidFilter, setIsRustendLidFilter] = React.useState<boolean>(false);
    const [filteredEnrollments, setFilteredEnrollments] = React.useState<IMatchEnrollment[]>(props.enrollments)

    const [matchLocation, setMatchLocation] = React.useState<Coords | null>(null);

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    React.useEffect(() => {
        if (!isAanwezigFilter && !isAfwezigFilter && !isRustendLidFilter) {
            const sortedEnrollments = [...props.enrollments].sort((a, b) => a.membership.user.username.localeCompare(b.membership.user.username));
            setFilteredEnrollments(sortedEnrollments);
            return;
        }

        let enrollments: IMatchEnrollment[] = [];
        if (isAanwezigFilter) {
            enrollments = props.enrollments.filter(e => e.status.toLocaleLowerCase() === "aanwezig")
        }
        if (isAfwezigFilter) {
            enrollments = enrollments.concat(props.enrollments.filter(e => e.status.toLocaleLowerCase() === "afwezig"));
        }
        if (isAfwezigFilter) {
            enrollments = enrollments.concat(props.enrollments.filter(e => e.status.toLocaleLowerCase() === "rustend lid"));
        }

        const sortedEnrollments = [...enrollments].sort((a, b) => a.membership.user.username.localeCompare(b.membership.user.username));
        setFilteredEnrollments(sortedEnrollments);
    }, [props.enrollments, isAanwezigFilter, isAfwezigFilter, isRustendLidFilter])

    const readStatusColor = (status: string) => (status == "aanwezig") ? theme.colors.success.main : theme.colors.error.main;

    React.useEffect(() => {
        // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
        Geocode.setApiKey("AIzaSyB1fbHrux9w5e0jbDUifWalk_JvjvzoOUM");

        // Get latitude & longitude from address.
        Geocode.fromAddress(match.location).then(
            (response) => {
                const { lat, lng } = response.results[0].geometry.location;
                setMatchLocation({
                    lat, lng
                });
            },
            (error) => {
                console.error(error);
            }
        );
    }, []);

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 34, height: 34, display: 'inline-block;', marginRight: '5px', verticalAlign: 'middle' }}
        />
            <b style={{ fontSize: '14px', textAlign: 'center' }}>{team.name}</b>
        </>);
    }
    const googleLink = match.location != null ? `https://www.google.com/maps/search/?api=1&query=${match.location}` : "";
    return (
        <Card variant="outlined">
            <CardHeader
                sx={{
                    p: 3
                }}
                disableTypography
                title={
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: `${theme.typography.pxToRem(16)}`
                        }}
                    >
                        <Box width="100%">
                            <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '80px', textAlign: 'center' }}>
                                    {renderTeam(match.team)}
                                </Grid>
                                <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center' }}>
                                    <h2>{moment(match.matchDate).format("HH:mm")}</h2>
                                </Grid>
                                <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '80px', textAlign: 'center' }}>
                                    {renderTeam(match.opponentTeam)}
                                </Grid>
                            </Grid>
                        </Box>
                        <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
                            <div><b>Datum:</b> {new Date(match.matchDate).toLocaleDateString()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</div>
                            <div><b>Adres:</b> <a href={googleLink}>{match.location}</a></div>
                            <div><b>Aanwezig:</b> {match.enrollments.filter(e => e.status === "Aanwezig" && e.membership.type === "Speler").length} spelers(s)</div>
                            <div><b>Afwezig:</b> {match.enrollments.filter(e => e.status === "Afwezig" && e.membership.type === "Speler").length} speler(s)</div>
                        </Typography>
                    </Typography>
                }
            />
            {matchLocation != null && match != null && (<>
                <LoadScript
                    googleMapsApiKey="AIzaSyDISt9DVtpHshhlXYGbAxnHmZaTbJHDhdY"
                >
                    <GoogleMap
                        mapContainerStyle={{
                            height: "200px",
                            width: "100%"
                        }}
                        center={matchLocation}
                        zoom={15}
                    >
                        <Marker position={matchLocation} />
                        <></>
                    </GoogleMap>
                </LoadScript>

                <Button href={googleLink}>Toon Route</Button>
            </>)}

            <Divider />
            <Box sx={{ padding: 2 }}>
                <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                        <FormControlLabel color="success"
                            control={<Checkbox color="success"
                                onClick={() => setIsAanwezigFilter(!isAanwezigFilter)} />}
                            label={<Text><b>AANWEZIG</b></Text>}
                        />
                        <FormControlLabel color="error"
                            control={<Checkbox color="error"
                                onClick={() => setIsAfwezigFilter(!isAfwezigFilter)} />}
                            label={<Text><b>AFWEZIG</b></Text>}
                        />
                    </FormGroup>
                </FormControl>
            </Box>
            <div id="list-container">
                <List sx={{ py: 0, display: "flex", flexWrap: "wrap" }} dense={true}>
                {(filteredEnrollments.length <= 0) && (
                    <Box sx={{ margin: 2 }}>
                        <Alert severity="info">Geen spelers gevonden</Alert>
                    </Box>
                ) || filteredEnrollments.filter(e => e.membership.type === "Speler").map(enrollment => (
                    <div key={enrollment.userId}>
                        <ListItem dense={true} sx={{ px: 2, py: 0.95, width: "300px" }}>
                            <LabelIcon
                                fontSize="medium"
                                sx={{ color: `${readStatusColor(enrollment.status.toLowerCase())}`, marginRight: '5px' }}
                            />
                            <ListItemAvatar sx={{ mr: 2, minWidth: 0 }}>
                                <Avatar src={enrollment.membership.user.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Link
                                        onClick={() => navigate(`/manage/users/${enrollment.membership.user.guid}/details`)}
                                        color="text.primary" variant="h5">
                                        {enrollment.membership.user.username}
                                    </Link>
                                }
                                secondary={
                                    <Typography variant="subtitle2" noWrap>
                                        <Typography sx={{ pr: 1 }} variant="h5" >
                                            {enrollment.membership.type} |  <Text color={enrollment.status.toLowerCase() === "aanwezig" ? "success" : "error"}>{enrollment.status.toUpperCase()}</Text>
                                        </Typography>
                                        <Typography variant="caption" noWrap>
                                            {new Date(enrollment.modifiedDate).toLocaleDateString()} om {new Date(enrollment.modifiedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                </Typography>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </div>
                ))}
                </List>
                <Divider />
                <Typography
                    variant="h3"
                    sx={{
                        margin: '10px',
                        marginTop: '15px',
                        fontSize: '18px'
                    }}
                >
                    Overige leden:
                </Typography>
                <List sx={{ py: 0, display: "flex", flexWrap: "wrap" }}>
                    {filteredEnrollments.filter(e => e.membership.type !== "Speler").map(enrollment => (
                    <div key={enrollment.userId}>
                        <ListItem key={enrollment.userId} sx={{ px: 2, py: 0.95, width: "300px" }}>
                            <LabelIcon
                                fontSize="medium"
                                sx={{ color: `${readStatusColor(enrollment.status.toLowerCase())}`, marginRight: '5px' }}
                            />
                            <ListItemAvatar sx={{ mr: 2, minWidth: 0 }}>
                                <Avatar src={enrollment.membership.user.avatar} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Link
                                        onClick={() => navigate(`/manage/users/${enrollment.membership.user.guid}/details`)}
                                        color="text.primary" variant="h5">
                                        {enrollment.membership.user.username}
                                    </Link>
                                }
                                secondary={
                                    <Typography variant="subtitle2" noWrap>
                                        <Typography sx={{ pr: 1 }} variant="h5" >
                                            {enrollment.membership.type} |  <Text color={enrollment.status.toLowerCase() === "aanwezig" ? "success" : "error"}>{enrollment.status.toUpperCase()}</Text>
                                        </Typography>
                                        <Typography variant="caption" noWrap>
                                            {new Date(enrollment.modifiedDate).toLocaleDateString()} om {new Date(enrollment.modifiedDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                        </Typography>
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <Divider />
                    </div>
                ))}
                </List>
            </div>
            {/*<Pagination count={10} />*/}
        </Card>
    );
}

export default MatchEnrollments;
