import * as React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { ListSubheader, alpha, Box, List, styled, Button, ListItem, Badge, Typography } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { SidebarContext } from 'contexts/SidebarContext';
import { MembershipService } from '../../../../services/membershipService';

// ICONS
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HomeIcon from '@mui/icons-material/Home';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import StadiumIcon from '@mui/icons-material/Stadium';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EventIcon from '@mui/icons-material/Event';

import BackpackIcon from '@mui/icons-material/Backpack';

const MenuWrapper = styled(Box)(
    ({ theme }) => `
    padding-bottom:  ${theme.spacing(1)};

  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.black[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.textColor};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.sidebar.textColor};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.sidebar.textColor};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.black[100], 0.06)};
            color: ${theme.colors.alpha.black[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.black[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.black[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
        'transform',
        'opacity'
    ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const SidebarMenu: React.FC = () => {
    const { closeSidebar } = React.useContext(SidebarContext);
    const { user, membership, loadMembership } = useAuth();
    const [requestMembershipCount, setRequestMembershipCount] = React.useState(0);

    const membershipService = new MembershipService();

    React.useEffect(() => {
        if (membership === null) {
            loadMembership();
        }
    }, [])

    React.useEffect(() => {
        if (user?.role == "Admin" || user?.role == "Teamleider") {
            membershipService.getMembershipRequests().then(memberships => setRequestMembershipCount(memberships?.length || 0));
        }
    }, [user])

    return (
        <>
            <MenuWrapper>
                <List component="div">
                    <SubMenuWrapper>
                        <List component="div">
                            <ListItem component="div">
                                <Button
                                    disableRipple
                                    component={RouterLink}
                                    onClick={closeSidebar}
                                    to="/overview"
                                    startIcon={<HomeIcon />}
                                >
                                    Home
                                </Button>
                            </ListItem>
                            {(membership != null && membership.type !== "Aanvraag") && (<>
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/agenda/matches"
                                        startIcon={<SportsSoccerIcon />}
                                    >
                                        Wedstrijden
                                    </Button>
                                </ListItem>
                                {(membership.team.name === "SV Reeshof 7") && (<>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/accountable/material"
                                            startIcon={<BackpackIcon />}
                                        >
                                            Materiaal ophalen
                                        </Button>
                                    </ListItem>
                                </>)}
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/agenda/events"
                                        startIcon={<EventIcon />}
                                    >
                                        Evenementen
                                    </Button>
                                </ListItem>
                                <ListItem component="div">
                                    <Button
                                        disableRipple
                                        component={RouterLink}
                                        onClick={closeSidebar}
                                        to="/topscorers"
                                        startIcon={<EmojiEventsIcon />}
                                    >
                                        Topscorers
                                    </Button>
                                </ListItem>
                                {(membership.team.name === "SV Reeshof 7") && (<>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/my/finance"
                                            startIcon={<EuroSymbolIcon />}
                                        >
                                            Sparen
                                        </Button>
                                    </ListItem>
                                </>)}
                            </>)}
                        </List>
                    </SubMenuWrapper>
                </List>
                {user != null && (user.role === "Admin" || user.role === "Teamleider" || user.role == "Financieel beheerder") && (
                    <List
                        component="div"
                        subheader={
                            <ListSubheader component="div" disableSticky>
                                Management
                            </ListSubheader>
                        }
                    >
                        <SubMenuWrapper>
                            <List component="div">
                                {user != null && (user.role === "Admin" || user.role === "Teamleider") && (<>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/manage/users"
                                            startIcon={<PeopleAltIcon />}
                                        >
                                            Gebruikers
                                        </Button>
                                    </ListItem>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/manage/memberships"
                                            startIcon={
                                                <Badge
                                                    sx={{ position: 'relative !important', right: '0px !important' }}
                                                    badgeContent={requestMembershipCount} color="primary"
                                                >
                                                    <PlaylistAddCheckIcon />
                                                </Badge>}
                                        >
                                            Aanvragen
                                        </Button>
                                    </ListItem>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/manage/matches"
                                            startIcon={<StadiumIcon />}
                                        >
                                            Wedstrijden
                                        </Button>
                                    </ListItem>
                                </>)}
                                {(membership && membership.team.name === "SV Reeshof 7") && (<>
                                    <ListItem component="div">
                                        <Button
                                            disableRipple
                                            component={RouterLink}
                                            onClick={closeSidebar}
                                            to="/manage/finance"
                                            startIcon={<EuroSymbolIcon />}
                                        >
                                            Financieel
                                        </Button>
                                    </ListItem>
                                </>)}
                            </List>
                        </SubMenuWrapper>
                    </List>
                )}
            </MenuWrapper>
        </>
    );
}

export default SidebarMenu;
