import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, Card, Checkbox, Chip, TablePagination, Divider, Typography, Grid, IconButton, Avatar, styled, TextField, Tooltip, InputAdornment, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomLabel from '../../CustomLabel';

import { IUserRole, IUser } from 'entities';

// ICONS
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    users: IUser[];
    query: string;
    pageNumber: number;
    filteredUsers: IUser[];
    selectedItems: string[];
    isSelectedBulkActions: boolean;
    isSelectedAllUsers: boolean;
    isSelectedSomeUsers: boolean;
    onUpdateSearchQuery: (query: string) => void;
    handleSelectAllUsers: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectOneUser: (event: React.ChangeEvent<HTMLInputElement>, userId: string) => void;
}

const CardWrapper = styled(Card)(
    ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const ManageUsersGrid: React.FC<IProps> = (props) => {
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();

    const [page, setPage] = React.useState<number>(Number(props.pageNumber) ?? 0);
    const [limit, setLimit] = React.useState<number>(10);

    const handlePageChange = (_event: any, newPage: number): void => {
        navigate('/manage/users/' + newPage);
        setPage(newPage);
    };
    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => setLimit(parseInt(event.target.value));

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        props.onUpdateSearchQuery(event.target.value);
        setPage(0);
    };

    //React.useEffect(() => setPage(0), [props.filteredUsers]);

    const renderRoleLabel = (userRole: IUserRole): JSX.Element => {
        switch (userRole) {
            case 'Admin':
                return <Chip sx={{ marginLeft: '15px' }} label="Admin" color="error" />
            case 'Teamleider':
                return <Chip sx={{ marginLeft: '15px' }} label="Teamleider" color="info" />
            case 'Speler':
                return <Chip sx={{ marginLeft: '15px' }} label="Speler" color="primary" />
            case 'Rustend Lid':
                return <Chip sx={{ marginLeft: '15px' }} label="Rustend Lid" color="warning" />
            case 'Lid':
                return <Chip sx={{ marginLeft: '15px' }} label="Lid" color="primary" />
            case 'Financieel beheerder':
                return <Chip label="Financieel beheer" color="secondary" />
            case 'Aanvraag':
                return <Chip sx={{ marginLeft: '15px' }} label="Aanvraag" color="info" />
            default:
                return <Chip sx={{ marginLeft: '15px' }} label="Onbekend" color="info" />
        }
    };

    const applyPagination = (users: IUser[], page: number, limit: number): IUser[] => users.slice(page * limit, page * limit + limit);
    const paginatedUsers = applyPagination(props.filteredUsers, page, limit);

    return (<>
        <Card sx={{ p: 2, mb: 3 }} >
            <Box display="flex" alignItems="center" justifyContent="space-between">
                {paginatedUsers.length !== 0 && (
                    <>
                        <Box display="flex" alignItems="center">
                            <Tooltip arrow placement="top" title={t('Select all users')}>
                                <Checkbox
                                    checked={props.isSelectedAllUsers}
                                    indeterminate={props.isSelectedSomeUsers}
                                    onChange={props.handleSelectAllUsers}
                                />
                            </Tooltip>
                        </Box>
                        {props.isSelectedBulkActions && (
                            <Box flex={1} pl={2}>
                                <>The Bulkactions should be rendered here..</>
                            </Box>
                        )}
                    </>
                )}
                {!props.isSelectedBulkActions && (
                    <TextField
                        sx={{ my: 0, ml: paginatedUsers.length !== 0 ? 2 : 0 }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleQueryChange}
                        placeholder={t('Search by name, email or username...')}
                        value={props.query}
                        size="small"
                        margin="normal"
                        variant="outlined"
                    />
                )}
            </Box>
        </Card>
        {paginatedUsers.length === 0 ? (
            <Typography
                sx={{
                    py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
            >
                {t("We hebben geen gebruikers gevonden.")}
            </Typography>
        ) : (<>
            <Grid container spacing={3}>
                {paginatedUsers.map((user) => {
                    const isUserSelected = props.selectedItems.includes(user.id.toString());
                    return (
                        <Grid item xs={12} sm={6} md={4} key={user.id}>
                            <CardWrapper className={isUserSelected ? 'Mui-selected' : ''}>
                                <Box sx={{ position: 'relative', zIndex: '2' }}  >
                                    <Box px={2} pt={2} display="flex" alignItems="flex-start" justifyContent="space-between">
                                        <Box pt={2} display="flex" alignItems="flex-start" justifyContent="space-between">
                                        {user.isActive && (
                                            <CustomLabel color="success">
                                                <DoneTwoToneIcon fontSize="small" />
                                                <b>{t('Actief')}</b>
                                            </CustomLabel>
                                        ) || (
                                                <CustomLabel color="error">
                                                    <ClearIcon fontSize="small" />
                                                    <b>{t('Inactief')}</b>
                                                </CustomLabel>
                                            )}
                                            {renderRoleLabel(user.role)}
                                        </Box>
                                        <IconButton color="primary" sx={{ p: 0.5 }}  >
                                            <MoreVertTwoToneIcon />
                                        </IconButton>
                                    </Box>
                                    <Box p={2} display="flex" alignItems="flex-start">
                                        <Avatar sx={{ width: 50, height: 50, mr: 2 }} src={user.avatar} />
                                        <Box>
                                            <Box>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    ({user.username})
                                                </Typography>
                                            </Box>
                                            <Typography sx={{ pt: 0.3 }} variant="subtitle2">
                                                {user.jobtitle}
                                            </Typography>
                                            <Typography sx={{ pt: 1 }} variant="h6">
                                                {user.email}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                style={{ fontStyle: 'italic' }}
                                                color="text.secondary"
                                            >
                                                {user.latestLogin ? moment(user.latestLogin).local().fromNow() : 'nog nooit'} voor het laatst ingelogd
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box pl={2} py={1} pr={1} display="flex" alignItems="center" justifyContent="space-between">
                                        <div>
                                            <Button sx={{ marginRight: '10px' }} variant="contained" size="small"
                                                onClick={() => navigate(`/manage/users/${user.guid}/details`)}
                                                startIcon={<EditIcon />}>
                                                Wijzig
                                            </Button>
                                            <Button variant="outlined" size="small" color="error"
                                                onClick={() => console.log("delete function not implemented")}
                                                startIcon={<DeleteIcon />}>
                                                Verwijder
                                            </Button>
                                        </div>
                                        <Checkbox
                                            checked={isUserSelected}
                                            onChange={(event) =>
                                                props.handleSelectOneUser(event, user.id.toString())
                                            }
                                            value={isUserSelected}
                                        />
                                    </Box>
                                </Box>
                            </CardWrapper>
                        </Grid>
                    );
                })}
            </Grid>
            <Card sx={{ p: 2, mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <TablePagination
                    component="div"
                    count={props.filteredUsers.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    labelRowsPerPage=""
                    rowsPerPageOptions={[5, 10, 15]}
                />
            </Card>
        </>
        )}
    </>);
};

export default ManageUsersGrid;
