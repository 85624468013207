/* eslint-disable no-mixed-operators */
import * as React from 'react';
import moment from 'moment';
import "moment/locale/nl";
import {
    Grid, Card, alpha, Tooltip, Box, Divider, Typography, Avatar, IconButton, List,
    ListItem, styled, Button, Pagination
} from '@mui/material';
import { IMatch, ITeam, IMembership } from 'entities';
import Loader from '../Loader';
import { ImagePath } from '../../images';

// ICONS
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import ControlPointTwoToneIcon from '@mui/icons-material/ControlPointTwoTone';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const ListItemWrapper = styled(ListItem)(
    ({ theme }) => `
      border-radius: 0;
      padding: ${theme.spacing(2)};
      
      &:hover {
        .MuiDate-wrapper {
            background: ${alpha(theme.colors.alpha.black[100], 0.08)};
        }
      }
`
);

interface IProps {
    userId: number;
    title: string;
    limit: number;
    membership: IMembership;
    matches: IMatch[];
    showMatchDetails: (match: IMatch) => void;
    updateMatch: (match: IMatch) => void;
    isLoading?: boolean;
}

const renderTeam = (team: ITeam) => {
    return (<><Avatar
        src={ImagePath(team.logoUrl)}
        sx={{ width: 34, height: 34, display: 'inline-block;', marginRight: '5px', verticalAlign: 'middle' }}
    />
        <b style={{ fontSize: '14px', textAlign: 'center' }}>{team.name}</b>
    </>);
}

const MatchSummary: React.FC<IProps> = (props) => {
    const [isMobile, setIsMobile] = React.useState(false)
    const [currentPage, setCurrentPage] = React.useState(1);

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);
    const matches = props.matches.slice(0, props.limit);

    const itemsPerPage = 3;
    const maxPageCount = Math.ceil(matches.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageMatches = matches.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <Card>
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <DateRangeTwoToneIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">{props.title}</Typography>
                </Box>
                <Tooltip placement="top" arrow title="Create new event">
                    <IconButton
                        sx={{
                            alignSelf: 'center'
                        }}
                        color="primary"
                    >
                        <ControlPointTwoToneIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Divider />
            {props.isLoading && (<>
                <Loader />
            </>) || pageMatches.length <= 0 && (
                <Typography sx={{ py: 5 }}
                    variant="h3"
                    fontWeight="normal"
                    color="text.secondary"
                    align="center"
                >
                    {"We hebben geen wedstrijden gevonden"}
                </Typography>
            ) || (<>
                <List disablePadding component="div">
                    {pageMatches.map(match => (<div key={match.matchDate.toString()}>
                        <Box width="100%" sx={{ padding: '5px' }}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="text.secondary" sx={{ margin: '8px 0px 0px 10px', float: "left" }}>
                                    {moment(match.matchDate).format('LL')}
                                </Typography>
                                <Button sx={{ marginInlineEnd: 1, float: "right" }} onClick={() => props.showMatchDetails(match)}>
                                    meer info..
                                </Button>
                            </Grid>
                        </Box>
                        <ListItemWrapper style={{ marginBottom: '25px', paddingBottom: 0 }}>
                            <Box width="100%">
                                <Grid sx={{ display: 'flex', justifyContent: 'space-around' }}>
                                    <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '200px !important' }}>
                                        {renderTeam(match.team)}
                                    </Grid>
                                    <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '60px !important' }}>
                                        <h2> {match.teamGoals} - {match.opponentTeamGoals}</h2>
                                    </Grid>
                                    <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '200px !important' }}>
                                        {renderTeam(match.opponentTeam)}
                                    </Grid>
                                </Grid>
                            </Box>
                        </ListItemWrapper>
                        <Divider />
                    </div>))}
                </List>
                {maxPageCount > 1 && (
                    <Pagination sx={{ padding: '15px' }}
                        count={maxPageCount} color="primary"
                        onChange={(event: React.ChangeEvent<unknown>, value: number) => setCurrentPage(value)}
                    />
                )}
            </>)}
        </Card>
    );
}

export default MatchSummary;
