import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from './App';
import { SidebarProvider } from './contexts/SidebarContext';
import * as serviceWorker from './registerServiceWorker';
import { AuthProvider } from './contexts/AuthContext';

ReactDOM.render(
    <HelmetProvider>
    <AuthProvider>
            <SidebarProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            </SidebarProvider>
        </AuthProvider>
    </HelmetProvider>,
    document.getElementById('root')
);

serviceWorker.unregister();


