import { IMatch, ITeam } from 'entities/index';
const servicePath = "/team";

export class TeamService {
    public getTeams = async (): Promise<ITeam[]> => {
        return await get(`/all`);
    }
}

const get = async (urlPath?: string, params?: any) => {
    const url = servicePath + (urlPath ?? '');
    const response = await fetch(url + '?' + new URLSearchParams(params), {
        method: 'GET',
        headers: {
            'x-api-key': "0eca5f9439914c65802a19491e957607"
        },
    })

    if (response.ok && response.status === 200) {
        return await response.json();
    }
    return null;
}