import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Container, CardContent, Card, Typography, Button, Divider, styled, Switch, SwitchProps } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { UserService } from 'services/userService';
import { getMembershipByUser, MembershipService, updateAutoEnrollment } from 'services/membershipService';
import { IMembership, IMembershipType, IUser } from 'entities';
import Text from 'components/Text';
import ProfileCover from './ProfileCover';
import CustomLabel from '../../CustomLabel';

// ICONS
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import EuroSymbol from '@mui/icons-material/EuroSymbol';
import EditUserInfoDialog from './Dialog/EditUserInfoDialog';
import EditMembershipDialog from './Dialog/EditMembershipDialog';
import MimavevaPage from '../../MimavevaPage';

const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    marginLeft: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const ManageUserProfile: React.FC = () => {
    const { userId } = useParams();

    const { t }: { t: any } = useTranslation();
    const { user, membership } = useAuth();

    const [isEditModeUserInfo, setIsEditModeUserInfo] = React.useState(false);
    const [isEditModeMembership, setIsEditModeMembership] = React.useState(false);

    const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);
    const [selectedMembership, setSelectedMembership] = React.useState<IMembership | null>(null);

    const userService = new UserService();
    const membershipService = new MembershipService();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    React.useEffect(() => {
        if (userId == null) {
            if (user == null) return;
            userService.getUser(user.guid).then(data => {
                setSelectedUser(data);
            });
            return;
        }

        userService.getUser(userId).then(data => {
            setSelectedUser(data);
        });
    }, []);

    React.useEffect(() => {
        if (selectedUser == null || user == null) {
            return;
        }
        //if (selectedUser.id == user.id) {
        //    setSelectedMembership(membership);
        //}

        // Load the membership of the selected user.
        getMembershipByUser(selectedUser.id).then(data => {
            setSelectedMembership(data);
        });

    }, [selectedUser, user])

    if (user == null || selectedUser == null) {
        return <></>;
    }

    const updateUserAutoEnrollment = (isEnabled: boolean): void => {
        if (selectedMembership == null) {
            return;
        }
        const updated = { ...selectedMembership, autoEnrollment: isEnabled };
        setSelectedMembership(updated);

        // Call service to save new status in the database.
        updateAutoEnrollment(selectedUser.id, isEnabled);
    }

    const onAddMembership = (): void => {
        if (selectedUser?.id === null) {
            return;
        }
        membershipService.addMembershipRequest(Number(selectedUser.id)).then(membership => {
            if (membership == null) {
                return;
            }

            const editmembership = { ...membership, type: "Speler" as IMembershipType }

            // Save the updated membership.
            membershipService.updateMembership(Number(selectedUser.id), editmembership).then(data => {
                // Set the membership of the selected user.
                setSelectedMembership(data);
                if (data != null) {
                    setSelectedUser(data.user);
                }
            });
        });
    }

    const isManageOptionsVisible = (user?.id === selectedUser.id) || (user.role === "Admin" || user.role === "Teamleider" || user.role === "Financieel beheerder");
    const isAdminOptionsVisible = (user.role === "Admin" || user.role === "Teamleider" );
    return (<>
       <MimavevaPage>
            <Helmet>
                <title>Gebruikersprofiel</title>
            </Helmet>
            <Container sx={{ paddingTop: "20px", paddingBottom: "100px"}}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={3}
                    marginBottom={5}
                >
                    <Grid item xs={12} md={12}>
                        <ProfileCover user={selectedUser} isViewOnly={!isManageOptionsVisible} />
                    </Grid>
                    <Grid item xs={12} md={isManageOptionsVisible ? 6 : 12}>
                        <Card>
                            <Box
                                p={3}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        {t('Persoonlijke Gegevens')}
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        {t('Manage informations related to your personal details')}
                                    </Typography>
                                </Box>
                                {isManageOptionsVisible == true && (
                                    <Button variant="text" startIcon={<EditTwoToneIcon />}
                                        onClick={() => setIsEditModeUserInfo(!isEditModeUserInfo)}>
                                        {t('Edit')}
                                    </Button>
                                )}
                            </Box>
                            <Divider />
                            <CardContent
                                sx={{
                                    p: 4
                                }}
                            >
                                <Typography variant="subtitle2">
                                    <Grid container spacing={0}>
                                        <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                {t('Naam')}:
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={9}>
                                            <Text color="black">
                                                <b>{selectedUser.username}</b>
                                            </Text>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                {t('Email')}:
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={9}>
                                            <Text color="black">
                                                <b>{selectedUser.email}</b>
                                            </Text>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                {t('Adres')}:
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={9}>
                                            <Text color="black">
                                                <b>{selectedUser.address ? selectedUser.address : "Onbekend"}</b>
                                            </Text>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                            <Box pr={3} pb={2}>
                                                {t('Verjaardag')}:
                                            </Box>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={9}>
                                            <Text color="black">
                                                <b>{selectedUser.dateOfBirth ? moment(selectedUser.dateOfBirth).format('LL')  : "Onbekend"}</b>
                                            </Text>
                                        </Grid>
                                    </Grid>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    {isManageOptionsVisible && (<>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <Box
                                    p={3}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Typography variant="h4" gutterBottom>
                                            {t('Account Instellingen')}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {t('Manage details related to your account')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                <CardContent
                                    sx={{
                                        p: 4
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        <Grid container spacing={0}>
                                            <Grid item xs={6} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Account')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{selectedUser.role}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Laatste login')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={8} md={9}>
                                                <Text color="black">
                                                    <b>{selectedUser.latestLogin == null ? '-' : moment(selectedUser.latestLogin).format('LL')}</b>
                                                </Text>
                                            </Grid>
                                            <Grid item xs={6} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                <Box pr={3} pb={2}>
                                                    {t('Account status')}:
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={8} md={9}>
                                                {selectedUser.isActive && (
                                                    <CustomLabel color="success">
                                                        <DoneTwoToneIcon fontSize="small" />
                                                        <b>{t('Actief')}</b>
                                                    </CustomLabel>
                                                ) || (
                                                        <CustomLabel color="error">
                                                            <ClearIcon fontSize="small" />
                                                            <b>{t('Inactief')}</b>
                                                        </CustomLabel>
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Card>
                                <Box
                                    p={3}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box>
                                        <Typography variant="h4" gutterBottom>
                                            {t('Lidmaatschap')}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {t('Manage details related to your membership')}
                                        </Typography>
                                    </Box>
                                    {(isAdminOptionsVisible || user.role == "Financieel beheerder") && (
                                        <Button variant="text" startIcon={<EditTwoToneIcon />}
                                            onClick={() => setIsEditModeMembership(true)}>
                                            {t('Edit')}
                                        </Button>
                                    )}
                                </Box>
                                <Divider />
                                <CardContent sx={{ p: 4 }} >
                                    {(selectedMembership == null && (isAdminOptionsVisible)) && (
                                        <Typography
                                            fontWeight="normal"
                                            color="text.secondary"
                                            align="center"
                                        >
                                            <Button variant="contained" onClick={() => onAddMembership()}>Lidmaatschap toevoegen</Button>
                                        </Typography>
                                    ) || (selectedMembership != null) && (
                                            <Typography variant="subtitle2">
                                                <Grid container spacing={0}>
                                                    <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                        <Box pr={3} pb={2}>
                                                            {t('Type')}:
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9}>
                                                        <Text color="black">
                                                            <b>{selectedMembership?.type}</b>
                                                        </Text>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                        <Box pr={3} pb={2}>
                                                            {t('Club')}:
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9}>
                                                        <Text color="black">
                                                            <b>{selectedMembership?.team.name}</b>
                                                        </Text>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                        <Box pr={3} pb={2}>
                                                            {t('Team')}:
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9}>
                                                        <Text color="black">
                                                            <b>{selectedMembership?.team.name}</b>
                                                        </Text>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                        <Box pr={3} pb={2}>
                                                            {t('Standaard aangemeld')}:
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9}>
                                                        <Text color="black">
                                                        <b><IOSSwitch disabled={true} checked={selectedMembership?.autoEnrollment} color={true ? "success" : "error"} name="Aangemeld" onClick={() => updateUserAutoEnrollment(!selectedMembership?.autoEnrollment)} /></b>
                                                        </Text>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={3} textAlign={{ sm: 'right' }}>
                                                        <Box pr={3} pb={2}>
                                                            {t('Saldo')}:
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9}>
                                                        {selectedMembership?.amountDue != null && (
                                                            <Text color={selectedMembership.amountDue <= 0 ? 'success' : 'error'}>
                                                                <b>
                                                                    <EuroSymbol style={{ fontSize: '14px', position: 'relative', top: '1.5px' }} /> {selectedMembership?.amountDue}
                                                                </b>
                                                            </Text>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        )}
                                </CardContent>
                            </Card>
                        </Grid>
                    </>)}
                </Grid>
            </Container>
        </MimavevaPage>
        <EditUserInfoDialog isOpen={isEditModeUserInfo} selectedUser={selectedUser}
            onClose={(user: IUser) => { setSelectedUser(user); setIsEditModeUserInfo(false); }} />

        {/*<EditAccountDialog isOpen={isEditModeAccount} selectedUser={selectedUser}
            onClose={(user: IUser) => { setSelectedUser(user); setIsEditModeAccount(false); }} />*/}

        {selectedMembership != null && (
            <EditMembershipDialog isOpen={isEditModeMembership} user={selectedUser} membership={selectedMembership} 
                onClose={(m: IMembership) => { setSelectedMembership(m); setIsEditModeMembership(false); }}/>
        )}
    </>);
}

export default ManageUserProfile;
