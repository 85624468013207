import * as React from 'react';
import { Box, Card, Divider, Typography, TableContainer, Table, TableHead, TableRow, TableCell, Checkbox, TableBody, Avatar, TablePagination, Tooltip, IconButton, TextField, InputAdornment } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IMatch } from 'entities';
import { ImagePath } from '../../../images';

// ICONS
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EditIcon from '@mui/icons-material/Edit';


interface IProps {
    matches: IMatch[];
    filteredItems: IMatch[];
    query: string;
    selectedItems: string[];
    isSelectedBulkActions: boolean;
    isSelectedAll: boolean;
    isSelectedSome: boolean;
    onUpdateSearchQuery: (query: string) => void;
    handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectOne: (event: React.ChangeEvent<HTMLInputElement>, userId: string) => void;
}

const MatchesTable: React.FC<IProps> = (props) => {
    const { t }: { t: any } = useTranslation();
    const location = useLocation();

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(10);

    const handlePageChange = (_event: any, newPage: number): void => setPage(newPage);
    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => setLimit(parseInt(event.target.value));

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        props.onUpdateSearchQuery(event.target.value);
    };

    const applyPagination = (matches: IMatch[], page: number, limit: number): IMatch[] => {
        return matches.slice(page * limit, page * limit + limit);
    };
    const paginatedItems = applyPagination(props.filteredItems, page, limit);

    return (<>
        <Card>
            <Box p={2}>
                {!props.isSelectedBulkActions && (
                    <TextField sx={{ m: 0 }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchTwoToneIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={handleQueryChange}
                        placeholder={t('Search by name, email or username...')}
                        value={props.query}
                        size="small"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />
                )}
                {props.isSelectedBulkActions && <>The Bulkactions should be rendered here..</>}
            </Box>
            <Divider />
            {paginatedItems.length === 0 ? (
                <>
                    <Typography sx={{ py: 10 }}
                        variant="h3"
                        fontWeight="normal"
                        color="text.secondary"
                        align="center"
                    >
                        {t("We hebben geen wedstrijden gevonden")}
                    </Typography>
                </>
            ) : (
                <>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={props.isSelectedAll}
                                            indeterminate={props.isSelectedSome}
                                            onChange={props.handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell>{t('Thuis')}</TableCell>
                                    <TableCell>{t('Uit')}</TableCell>
                                    <TableCell>{t('Locatie')}</TableCell>
                                    <TableCell>{t('Datum')}</TableCell>
                                    <TableCell>{t('Status')}</TableCell>
                                    <TableCell>{t('Info')}</TableCell>
                                    <TableCell align="center">{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedItems.map((match: IMatch) => {
                                    const isSelected = props.selectedItems.includes(match.id.toString());
                                    return (
                                        <TableRow hover key={match.id} selected={isSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    checked={isSelected}
                                                    onChange={(event) => props.handleSelectOne(event, match.id.toString())}
                                                    value={isSelected}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h5">
                                                    <Box display="flex" alignItems="center">
                                                        <Avatar
                                                            src={ImagePath(match.team.logoUrl)}
                                                            sx={{ width: 32, height: 32, marginRight: 2 }}
                                                        />
                                                        <Box>
                                                            <Typography noWrap variant="subtitle2">
                                                                {match.team.name}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar
                                                        src={ImagePath(match.opponentTeam.logoUrl)}
                                                        sx={{ width: 32, height: 32, marginRight: 2 }}
                                                    />
                                                    <Box>
                                                        <Typography noWrap variant="subtitle2">
                                                            {match.opponentTeam.name}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Typography>{match.location}</Typography>
                                            </TableCell>
                                            <TableCell>
                                                {new Date(match.matchDate).toLocaleDateString()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                            </TableCell>
                                            <TableCell>
                                                <Typography fontWeight="bold">
                                                    {match.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{match.info}</TableCell>
                                            <TableCell align="center">
                                                <Typography noWrap>
                                                    <Tooltip title={t('View/Edit')} arrow>
                                                        <IconButton
                                                            component={RouterLink}
                                                            to={
                                                                `/${location.pathname.split('/')[1]
                                                                }/match/` + match.id + `/details`
                                                            }
                                                            color="primary"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t('Delete')} arrow>
                                                        <IconButton
                                                            //onClick={handleConfirmDelete}
                                                            color="primary"
                                                        >
                                                            <DeleteTwoToneIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box p={2}>
                        <TablePagination
                            component="div"
                            count={props.filteredItems.length}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleLimitChange}
                            page={page}
                            rowsPerPage={limit}
                            rowsPerPageOptions={[5, 10, 15]}
                        />
                    </Box>
                </>
            )}
        </Card>
    </>);
}

export default MatchesTable;
