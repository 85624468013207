import * as  React from 'react';
import { Avatar, Box, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

// ICONS
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { SidebarContext } from '../../../../contexts/SidebarContext';
import { useAuth } from 'contexts/AuthContext';

const ListWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {ine

                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

function HeaderMenu() {
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = React.useState(false)
    const { sidebarToggle, toggleSidebar } = React.useContext(SidebarContext);

    const { membership } = useAuth();

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <Box
                component="span"
                sx={{
                    display: { lg: 'none', xs: 'inline-block' }
                }}
            >
                <Tooltip arrow title="Toggle Menu">
                    <IconButton onClick={toggleSidebar} sx={{ color: "white" }}>
                        {!sidebarToggle ? (
                            <MenuTwoToneIcon fontSize="medium" />
                        ) : (
                            <CloseTwoToneIcon fontSize="medium" />
                        )}
                    </IconButton>
                </Tooltip>
            </Box>
            <h3 style={{ marginLeft: '10px', whiteSpace: 'nowrap', width: '135px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {membership ? membership.team.name : "MIMAVEVA"}
            </h3>
            <ListWrapper
                sx={{
                    display: {
                        xs: 'none',
                        md: 'block'
                    }
                }}
            >
                <List disablePadding component={Box} display="flex">
                    <ListItem
                        classes={{ root: 'MuiListItem-indicators' }}
                        button
                        component={NavLink}
                        to="/"
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary="Home"
                        />
                    </ListItem>
                </List>
            </ListWrapper>
        </>
    );
}

export default HeaderMenu;
