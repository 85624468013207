const images = {
    logos: {
        default: require('./logo/teams/default.png'),
        vvgilze: require('./logo/teams/vvgilze.png'),
        svgtilburg: require('./logo/teams/svgtilburg.png'),
        tsvvmerlijn: require('./logo/teams/tsvvmerlijn.png'),
        gsbw: require('./logo/teams/gsbw.png'),
        viola: require('./logo/teams/viola.png'),
        vvriel: require('./logo/teams/vvriel.png'),
        rksvweredi: require('./logo/teams/rksv-were-di.png'),
        fctilburg: require('./logo/teams/fctilburg.png'),
        berkdijk: require('./logos-club/berkdijk.png'),
        blauwwit: require('./logos-club/blauwwit81.png'),
        dongen: require('./logos-club/dongen.png'),
        dvvc: require('./logos-club/dvvc.png'),
        ovv67: require('./logos-club/ovv67.png'),
        sarto: require('./logos-club/sarto.png'),
        sctzand: require('./logos-club/sctzand.png'),
        svreeshof: require('./logos-club/svreeshof.png'),
        unoanimo: require('./logos-club/unoanimo.png'),
        willem2: require('./logos-club/willem2.png'),
        haarsteeg: require('./logos-club/haarsteeg.png'),
        nieuwkuijk: require('./logos-club/nieuwkuijk.png'),
        noad32: require('./logos-club/noad32.png'),
        rkdsv: require('./logos-club/rkdsv.png'),
        sco: require('./logos-club/sco.png'),
        ssc55: require('./logos-club/ssc55.png'),
        voab: require('./logos-club/voab.png'),
        wsc: require('./logos-club/wsc.png'),
        zwaluwvfc: require('./logos-club/zwaluwvfc.png'),
        gudok: require('./logos-club/gudok.png'),
    }
};

const BlobStorageEndpoint = "https://mimavevablob.blob.core.windows.net/mimaveva";

export const ImagePath = (image: string): string => {

    return `${BlobStorageEndpoint}${image}.png`

    // BELOW CONSTRUCTION IS OBSOLETE.
    // Resolve image path or return src url.
    switch (image) { 
        case "/logo/teams/svreeshof": 
            return images.logos.svreeshof.default;
        case "/logo/teams/vvgilze":
            return images.logos.vvgilze.default;
        case "/logo/teams/svgtilburg":
            return images.logos.svgtilburg.default;
        case "/logo/teams/sctzand":
            return images.logos.sctzand.default;
        case "/logo/teams/tsvvmerlijn":
            return images.logos.tsvvmerlijn.default;
        case "/logo/teams/gsbw":
            return images.logos.gsbw.default;
        case "/logo/teams/viola":
            return images.logos.viola.default;
        case "/logo/teams/vvriel":
            return images.logos.vvriel.default;
        case "/logo/teams/rksv-were-di":
            return images.logos.rksvweredi.default;
        case "/logo/teams/fctilburg":
            return images.logos.fctilburg.default;
        case "/logo/teams/sarto":
            return images.logos.sarto.default;
        case "/logo/teams/berkdijk":
            return images.logos.berkdijk.default;
        case "/logo/teams/blauwwit81":
            return images.logos.blauwwit.default;
        case "/logo/teams/dongen":
            return images.logos.dongen.default;
        case "/logo/teams/dvvc":
            return images.logos.dvvc.default;
        case "/logo/teams/ovv67":
            return images.logos.ovv67.default;
        case "/logo/teams/unoanimo":
            return images.logos.unoanimo.default;
        case "/logo/teams/willem2":
            return images.logos.willem2.default;
        case "/logo/teams/haarsteeg":
            return images.logos.haarsteeg.default;
        case "/logo/teams/nieuwkuijk":
            return images.logos.nieuwkuijk.default;
        case "/logo/teams/noad32":
            return images.logos.noad32.default;
        case "/logo/teams/rkdsv":
            return images.logos.rkdsv.default;
        case "/logo/teams/sco":
            return images.logos.sco.default;
        case "/logo/teams/ssc55":
            return images.logos.ssc55.default
        case "/logo/teams/voab":
            return images.logos.voab.default;
        case "/logo/teams/wsc":
            return images.logos.wsc.default;
        case "/logo/teams/zwaluwvfc":
            return images.logos.zwaluwvfc.default;
        case "/logo/teams/gudok":
            return images.logos.gudok.default;
        default:
            return images.logos.default.default;
    }
};

export default images;