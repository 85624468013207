import * as React from 'react';
import { Avatar, Grid, Typography, LinearProgress, Box, styled } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Text from 'components/Text';

// ICONS
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';

const AvatarWrapperError = styled(Avatar)(
    ({ theme }) => `
      width: ${theme.spacing(9)};
      height: ${theme.spacing(9)};
      margin-right: ${theme.spacing(1.5)};
      border-radius: ${theme.general.borderRadius};
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
`
);

const AvatarWrapperSuccess = styled(Avatar)(
    ({ theme }) => `
      width: ${theme.spacing(9)};
      height: ${theme.spacing(9)};
      margin-right: ${theme.spacing(1.5)};
      border-radius: ${theme.general.borderRadius};
      background-color: ${theme.colors.success.lighter};
      color:  ${theme.colors.success.main};
`
);

interface IProps {
    pendingAmount: number;
}

const BudgetWidget: React.FC<IProps> = (props) => {
    const { t }: { t: any } = useTranslation();
    const totalAmount = 2740;
    const payedAmount = totalAmount - props.pendingAmount;

    return (
        <Box px={2} pt={1}>
            <Grid container textAlign="center">
                <Grid xs item>
                    <Typography variant="h4" gutterBottom>
                        Openstaand
                    </Typography>
                    <Text color="error">
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="body1" fontWeight="bold">
                                -{(((totalAmount - payedAmount) / totalAmount) * 100).toFixed(2)}%
                            </Typography>
                            <ArrowDownwardIcon fontSize="small" />
                        </Box>
                    </Text>
                </Grid>
                <Grid xs item>
                    <Typography variant="h4" gutterBottom>
                        Betaald
                    </Typography>
                    <Text color="success">
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Typography variant="body1" fontWeight="bold">
                                +{((payedAmount / totalAmount) * 100).toFixed(2)}%
                            </Typography>
                            <ArrowUpwardIcon fontSize="small" />
                        </Box>
                    </Text>
                </Grid>
            </Grid>
            <Box display="flex" alignItems="center" sx={{ pt: 4, pb: 1 }}            >
                <AvatarWrapperSuccess>
                    <MonetizationOnTwoToneIcon fontSize="large" />
                </AvatarWrapperSuccess>
                <Box sx={{ flex: '1' }}                >
                    <Typography variant="subtitle1" gutterBottom color="text.secondary">
                        {t('Betaald')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                    >
                        <Typography variant="h4" color="text.primary">
                            €{payedAmount}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            €{totalAmount}
                        </Typography>
                    </Box>
                    <LinearProgress value={90} color="primary" variant="determinate" />
                </Box>
            </Box>
            {/*<Typography variant="body2" color="text.secondary">*/}
            {/*    {t('Deadline voor betaling')} {' '}*/}
            {/*    <Typography*/}
            {/*        variant="body2"*/}
            {/*        component="span"*/}
            {/*        fontWeight="bold"*/}
            {/*        color="text.primary"*/}
            {/*    >*/}
            {/*        {moment(new Date('01-01-2023')).fromNow()}*/}
            {/*    </Typography>*/}
            {/*</Typography>*/}
            <Box
                display="flex"
                alignItems="center"
                sx={{ pt: 3, pb: 5 }}
            >
                <AvatarWrapperError>
                    <MonetizationOnTwoToneIcon fontSize="large" />
                </AvatarWrapperError>
                <Box
                    sx={{ flex: '1' }}
                >
                    <Typography variant="subtitle1" gutterBottom color="text.secondary">
                        {t('Openstaand')}
                    </Typography>
                    <Box
                        display="flex"
                        alignItems="flex-end"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                    >
                        <Typography variant="h4" color="text.primary">
                            €{totalAmount - payedAmount}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            €{totalAmount}
                        </Typography>
                    </Box>
                    <LinearProgress value={10} color="primary" variant="determinate" />
                </Box>
            </Box>
        </Box>
    );
}

export default BudgetWidget;
