import * as React from 'react';
import { FC, ReactNode } from 'react';
import { Box, alpha, lighten, useTheme, BottomNavigation, BottomNavigationAction, styled } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

// ICONS
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';

interface SidebarLayoutProps {
    children?: ReactNode;
}

const BottomMenuWrapper = styled(Box)(
    ({ theme }) => `
        height: ${theme.bottomMenu.height};
        color: ${theme.bottomMenu.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        bottom: 0;
        z-index: 77;
        background-color: ${theme.bottomMenu.background};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

const SidebarLayout: FC<SidebarLayoutProps> = () => {
    const theme = useTheme();
    const { isAuthenticated, isLoadingAuth, membership } = useAuth();
    const navigate = useNavigate();

    return (
        ((isLoadingAuth) && (<>Loading</>)) || (
            <>
                <Box
                    sx={{
                        flex: 1,
                        height: '100%',

                        '.MuiPageTitle-wrapper': {
                            background:
                                theme.palette.mode === 'dark'
                                    ? theme.colors.alpha.trueWhite[5]
                                    : theme.colors.alpha.white[50],
                            marginBottom: `${theme.spacing(4)}`,
                            boxShadow:
                                theme.palette.mode === 'dark'
                                    ? `0 1px 0 ${alpha(
                                        lighten(theme.colors.primary.main, 0.7),
                                        0.15
                                    )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                                    : `0px 2px 4px -3px ${alpha(
                                        theme.colors.alpha.black[100],
                                        0.1
                                    )}, 0px 5px 12px -4px ${alpha(
                                        theme.colors.alpha.black[100],
                                        0.05
                                    )}`
                        }
                    }}
                >
                    <Header />
                    <Sidebar />
                    {membership != null && (
                        <BottomMenuWrapper display="block" sx={{ height: '75px'}}>
                            <BottomNavigation
                                showLabels
                            >
                                <BottomNavigationAction label="Home" icon={<HomeIcon />} onClick={() => navigate('/overview')} />
                                <BottomNavigationAction label="Wedstrijden" icon={<SportsSoccerIcon />} onClick={() => navigate('/agenda/matches')} />
                                <BottomNavigationAction label="Topscorers" icon={<EmojiEventsIcon />} onClick={() => navigate('/topscorers')} />
                                <BottomNavigationAction label="Profiel" icon={<PersonIcon />} onClick={() => navigate('/manage/users/my/details')} />
                            </BottomNavigation>
                        </BottomMenuWrapper>
                    )}
                    <Box
                        sx={{
                            position: 'relative',
                            zIndex: 5,
                            display: 'block',
                            flex: 1,
                            pt: `${theme.header.height}`,
                            [theme.breakpoints.up('lg')]: {
                                ml: `${theme.sidebar.width}`
                            }
                        }}
                    >
                        <Box display="block">
                            <Outlet />
                        </Box>
                    </Box>
                </Box>
            </>)
    );
};

export default SidebarLayout;
