import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid } from '@mui/material';
import NotificationsList from './NotificationsList';

import styles from 'styles/modules/app.module.scss';
import NotificationItem from './NotificationItem';
import { useParams } from 'react-router';

const NotificationsPage: React.FC = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const { notificationId } = useParams();

    return (
            <Box className={styles.overview}>
                <Helmet>
                    <title>Topscorers</title>
                </Helmet>
                <Container>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                        marginBottom={5}
                    >
                        <Grid item xs={12} md={10}>
                            {notificationId != null ? <NotificationItem /> : <NotificationsList />}
                        </Grid>
                    </Grid>
                </Container>
            </Box>
    );
}

export default NotificationsPage;