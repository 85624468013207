import * as React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Box, Divider, IconButton, Tooltip, Typography, ListItemText, ListItem, List, Menu, lighten, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import { useAuth } from '../../contexts/AuthContext';
import { Mail } from '../../entities';
import moment from 'moment';
import { NotificationService } from '../../services/notificationService';

const BoxContent = styled(Box)(
    ({ theme }) => `
      border-radius: ${theme.general.borderRadius};
      background: ${theme.colors.alpha.black[5]};
      font-size: ${theme.typography.pxToRem(15)};
`
);

function NotificationItem() {
    const { user } = useAuth();
    const { t }: { t: any } = useTranslation();
    const [isMobile, setIsMobile] = React.useState(false)

    const [onMenuOpen, menuOpen] = React.useState<boolean>(false);
    const moreRef = React.useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const { notificationId, userId } = useParams();

    const [notification, setNotification] = React.useState<Mail | null>(null);

    const notificationService = new NotificationService();

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }, []);

    React.useEffect(() => {
        if (notificationId == null) {
            return;
        }

        notificationService.getNotification(notificationId, userId).then((notification) => {
            if (notification == null) {
                return 
            }

            setNotification({
                id: '1',
                category: 'test',
                subject: notification.subject,
                content: notification.content,
                createdDate: notification.createdDate,
                from: {
                    name: 'Mimaveva',
                    email: 'mimaveva@',
                    avatar: 'https://www.iconpacks.net/icons/1/free-soccer-icon-459-thumb.png'
                },
                to: [{
                    name: user?.username ?? "",
                    email: user?.email ?? "",
                    avatar: user?.avatar ?? ""
                }],
                date: 1,
                opened: false,
                summary: 'dsda',
                tagIds: []
            });
        });

    }, [notificationId]);

    const openMenu = (): void => {
        menuOpen(true);
    };

    const closeMenu = (): void => {
        menuOpen(false);
    };

    const handleBack = (): void => {
        return navigate(-1);
    };


    if (!notification || user == null) {
        return null;
    }

    return (<>
        <Box sx={{
            backgroundColor: 'white',
        }} >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                px={3}
                sx={{
                    backgroundColor: 'white',
                    pt: { lg: 3 }
                }}
                pb={3}
            >
                <Tooltip arrow placement="top" title={t('Go back')}>
                    <IconButton
                        color="primary"
                        sx={{
                            p: 1
                        }}
                        onClick={handleBack}
                    >
                        <ArrowBackTwoToneIcon />
                    </IconButton>
                </Tooltip>

                <Box>
                    <Tooltip arrow placement="top" title={t('Archive')}>
                        <IconButton
                            color="primary"
                            sx={{
                                ml: 1,
                                p: 1
                            }}
                        >
                            <ArchiveTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="top" title={t('Delete')}>
                        <IconButton
                            color="primary"
                            sx={{
                                p: 1
                            }}
                        >
                            <DeleteTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip arrow placement="top" title={t('Markeren als gelezen')}>
                        <IconButton
                            color="primary"
                            sx={{
                                p: 1
                            }}
                        >
                            <MarkEmailReadTwoToneIcon />
                        </IconButton>
                    </Tooltip>
                    <IconButton
                        color="primary"
                        onClick={openMenu}
                        ref={moreRef}
                        sx={{
                            ml: 1,
                            p: 1
                        }}
                    >
                        <MoreVertTwoToneIcon />
                    </IconButton>
                </Box>
            </Box>
            <Divider />
            <Box
                px={0}
                display="flex"
                alignItems="flex-center"
                flexWrap="wrap"
                justifyContent="space-between"
            >
            <Typography
                    sx={{
                    textAlign: 'center',
                    py: 1,
                    px: isMobile ? 2 : 10
                }}
                variant="h3"
            >
                {notification.subject}
            </Typography>
                {/*<Box display="flex" alignItems="center" sx={{ marginBottom: 2 }}>*/}
                {/*    <Avatar*/}
                {/*        sx={{*/}
                {/*            mr: 2,*/}
                {/*        }}*/}
                {/*        src={notification.from.avatar ?? ''}*/}
                {/*    />*/}
                {/*    <Box>*/}
                {/*        <Typography variant="h4" gutterBottom>*/}
                {/*            {notification.from.email}{' '}*/}
                {/*            <Typography*/}
                {/*                component="span"*/}
                {/*                variant="h5"*/}
                {/*                color="text.secondary"*/}
                {/*                fontWeight="normal"*/}
                {/*            >*/}
                {/*                ({notification.from.name})*/}
                {/*            </Typography>*/}
                {/*        </Typography>*/}
                {/*        <Typography variant="h5" color="text.secondary" fontWeight="normal">*/}
                {/*            {t('to')}{' '}*/}
                {/*            {notification.to.map((to) => (*/}
                {/*                <span key={to.name}>*/}
                {/*                    {to.name} ({to.email})*/}
                {/*                </span>*/}
                {/*            ))}*/}
                {/*        </Typography>*/}
                {/*    </Box>*/}
                {/*</Box>*/}
                <Box sx={{ paddingLeft: '50px' }}>
                    <Typography align="center" variant="subtitle2" color="text.primary">
                        {moment(notification.createdDate).format("dd, MMMM DD YYYY, h:mm:ss a")}
                    </Typography>
                    <Typography align="center" variant="subtitle1">
                        {moment(notification.createdDate).fromNow()}
                    </Typography>
                </Box>
            </Box>
            <BoxContent px={4} py={1} mt={1} mb={4} ml={isMobile ? 3 : 10} mr={3}>
                <div dangerouslySetInnerHTML={{ __html: notification.content }} />
            </BoxContent>
            <Divider />
            <Menu
                disableScrollLock
                keepMounted
                anchorEl={moreRef.current}
                open={onMenuOpen}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List
                    sx={{
                        p: 1
                    }}
                    component="nav"
                >
                    <ListItem button>
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true
                            }}
                            primary={t('Mareren als gelezen')}
                        />
                    </ListItem>
                    <ListItem button>
                        <ListItemText
                            primaryTypographyProps={{
                                noWrap: true
                            }}
                            primary={t('Markeren as belangrijk')}
                        />
                    </ListItem>
                </List>
            </Menu>
        </Box>
    </>);
}

export default NotificationItem;
