import * as React from 'react';
import { Box, Card, Avatar, Typography, Divider, useTheme, styled } from '@mui/material';

// ICONS
import StoreIcon from '@mui/icons-material/Store';
import BrugmansGroepLogo from 'images/logo/brugmans-groep.jpg';

import VerhijkoLogo from '../../images/logo/verhijko.gif';
import MMWebdesignLogo from '../../images/logo/mm-webdesign.png';
import DronekopenonlineLogo from '../../images/logo/dronekopenonline.png';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const renderLogo = (url: string) => {
    return (<div style={{ margin: "20px", display: 'flex', alignItems: 'center' }}>
        <img style={{ display: 'flex', maxWidth: '250px', maxHeight: '110px' }} alt="sponsor" src={url} />
    </div>);
}

function SponsorsCard() {
    return (
        <Card variant="outlined">
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary sx={{ mr: 1.5 }}>
                        <StoreIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">SPONSORS</Typography>
                </Box>
            </Box>
            <Divider />
            <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', margin: '20px'}}>

                {renderLogo(BrugmansGroepLogo)}
                {renderLogo(VerhijkoLogo)}
                {renderLogo(MMWebdesignLogo)}
                {renderLogo(DronekopenonlineLogo)}
            </Box>
        </Card>
    );
}

export default SponsorsCard;
