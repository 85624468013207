import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Avatar, Box, Card, Slide, Button, Typography, Dialog, styled, Container, CardContent, FormGroup } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'contexts/AuthContext';
import { getClubMatches } from "services/matchService";
import { IMatch, IUserRole } from 'entities';
import Footer from 'components/Footer';
import ManageMatchesTab from './ManageMatchesTab';
import MatchesTable from './MatchesTable'

// ICONS
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

import styles from 'styles/modules/app.module.scss';
import moment from 'moment';
import MatchesGrid from './MatchesGrid';
import { useNavigate, useParams } from 'react-router';
import Loader from '../../Loader';

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);
const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

interface Filters {
    role?: IUserRole;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (matches: IMatch[], query: string, filters: Filters): IMatch[] => {
    return matches.filter((match) => {
        let matches = true;

        if (query) {
            const properties = ['location', 'status'];
            let containsQuery = false;

            properties.forEach((property) => {
                if (match[property].toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });

            if (!containsQuery) {
                matches = false;
            }
        }

        Object.keys(filters).forEach((key: string) => {
            const value = filters[key as keyof Filters];
            if (value && match[key] !== value) {
                matches = false;
            }
        });

        return matches;
    });
};

const ManageMatches: React.FC = () => {
    const { membership } = useAuth();
    const navigate = useNavigate();
    const { pageId } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [matches, setMatches] = React.useState<IMatch[]>([]);

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setToggleView("grid_view");
        }
    }, []);

    React.useEffect(() => {
        if (membership == null) {
            return;
        }
        setIsLoading(true);
        // Load the matches of the current membership club.
        getClubMatches(membership.team.id).then(data => {
            setMatches(data || [])
            setIsLoading(false);
        });

    }, [membership])

    const [selectedItems, setSelectedMatches] = React.useState<string[]>([]);
    const { t }: { t: any } = useTranslation();

    const [query, setQuery] = React.useState<string>('');
    const [filters, setFilters] = React.useState<Filters>({
        role: null
    });

    const handleTabsChange = (_event: React.SyntheticEvent, tabsValue: unknown) => {
        let value: IUserRole = null;

        if (tabsValue !== 'all') {
            value = tabsValue as IUserRole;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

        setSelectedMatches([]);
    };

    const handleSelectAllMatches = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedMatches(event.target.checked ? matches.map((match) => match.id.toString()) : []);
    };

    const handleSelectOneMatch = (
        _event: React.ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        if (!selectedItems.includes(userId)) {
            setSelectedMatches((prevSelected) => [...prevSelected, userId]);
        } else {
            setSelectedMatches((prevSelected) =>
                prevSelected.filter((id) => id !== userId)
            );
        }
    };

    const filteredMatches = applyFilters(matches, query, filters).sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate)));;
    const selectedBulkActions = selectedItems.length > 0;
    const selectedSomeMatches = selectedItems.length > 0 && selectedItems.length < matches.length;
    const selectedAllMatches = selectedItems.length === matches.length;

    const [toggleView, setToggleView] = React.useState<string | null>('table_view');
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

    const closeConfirmDelete = () => {
        setOpenConfirmDelete(false);
    };

    const handleDeleteCompleted = () => {
        setOpenConfirmDelete(false);
    };

    return (
        <>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Wedstrijden beheren</title>
                </Helmet>
                <Container>
                    <Card>
                        <CardContent>
                            <ManageMatchesTab toggleView={toggleView}
                                updateView={(view) => setToggleView(view)}
                                onTabChange={handleTabsChange}
                            />
                            <Button sx={{ margin: '10px 10px 10px 0px' }} variant="contained" size="small"
                                onClick={() => navigate(`/manage/match/create`)}
                                startIcon={<AddIcon />}>
                                Toevoegen
                            </Button>
                            {isLoading && (<>
                                <Loader />
                            </>) || (<>
                                {toggleView === 'table_view' && (
                                    <MatchesTable matches={matches} query={query}
                                        filteredItems={filteredMatches}
                                        selectedItems={selectedItems}
                                        isSelectedBulkActions={selectedBulkActions}
                                        isSelectedAll={selectedAllMatches}
                                        isSelectedSome={selectedSomeMatches}
                                        handleSelectAll={handleSelectAllMatches}
                                        handleSelectOne={handleSelectOneMatch}
                                        onUpdateSearchQuery={(query: string) => setQuery(query)}
                                    />
                                )}
                                {toggleView === 'grid_view' && (<>
                                    <MatchesGrid matches={matches} query={query}
                                        filteredItems={filteredMatches}
                                        selectedItems={selectedItems}
                                        isSelectedBulkActions={selectedBulkActions}
                                        isSelectedAll={selectedAllMatches}
                                        isSelectedSome={selectedSomeMatches}
                                        handleSelectAll={handleSelectAllMatches}
                                        handleSelectOne={handleSelectOneMatch}
                                        onUpdateSearchQuery={(query: string) => setQuery(query)}
                                    />
                                </>)}
                                {!toggleView && (
                                    <Card sx={{ textAlign: 'center', p: 3 }}>
                                        <Typography
                                            align="center"
                                            variant="h4"
                                            fontWeight="normal"
                                            color="text.secondary"
                                            sx={{
                                                my: 5
                                            }}
                                            gutterBottom
                                        >
                                            {t('Choose between table or grid views for displaying the users list.')}
                                        </Typography>
                                    </Card>
                                )}
                                <DialogWrapper
                                    open={openConfirmDelete}
                                    maxWidth="sm"
                                    fullWidth
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={closeConfirmDelete}
                                >
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                        flexDirection="column"
                                        p={5}
                                    >
                                        <AvatarError>
                                            <CloseIcon />
                                        </AvatarError>

                                        <Typography
                                            align="center"
                                            sx={{
                                                py: 4,
                                                px: 6
                                            }}
                                            variant="h3"
                                        >
                                            {t('Are you sure you want to permanently delete this user account')} ?
                                        </Typography>

                                        <Box>
                                            <Button
                                                variant="text"
                                                size="large"
                                                sx={{
                                                    mx: 1
                                                }}
                                                onClick={closeConfirmDelete}
                                            >
                                                {t('Cancel')}
                                            </Button>
                                            <ButtonError
                                                onClick={handleDeleteCompleted}
                                                size="large"
                                                sx={{
                                                    mx: 1,
                                                    px: 3
                                                }}
                                                variant="contained"
                                            >
                                                {t('Delete')}
                                            </ButtonError>
                                        </Box>
                                    </Box>
                                </DialogWrapper>
                            </>)}
                        </CardContent>
                    </Card>
                </Container>
                <Footer />
            </Box>
        </>
    );
};

ManageMatches.propTypes = {
    matches: PropTypes.array.isRequired
};

ManageMatches.defaultProps = {
    matches: []
};

export default ManageMatches;
