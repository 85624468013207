import * as React from 'react';
import moment from 'moment';
import { Box, Card, Typography, Avatar, Divider, alpha, styled, useTheme, List, ListItem, ListItemAvatar, ListItemText, Button, LinearProgress, linearProgressClasses, Grid } from '@mui/material';
import Text from 'components/Text';
import { IMatch, ITeam } from 'entities';

import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from '../Loader';
import { ImagePath } from '../../images';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const LinearProgressGreen = styled(LinearProgress)(
    ({ theme }) => `
        border-radius: ${theme.general.borderRadiusSm};

        &.${linearProgressClasses.colorPrimary} {
            height: 26px;
            background-color: ${alpha(theme.colors.success.main, 0.06)};
            box-shadow: inset 0 1px 2px ${alpha(theme.colors.success.dark, 0.2)};
        }
        
        & .${linearProgressClasses.bar} {
            height: 12px;
            margin-top: 7px;
            border-radius: ${theme.general.borderRadiusSm};
            background: ${theme.colors.gradients.green2};
        }
    `
);

const LinearProgressRed = styled(LinearProgress)(
    ({ theme }) => `
        border-radius: ${theme.general.borderRadiusSm};

        &.${linearProgressClasses.colorPrimary} {
            height: 26px;
            background-color: ${alpha(theme.colors.error.main, 0.06)};
            box-shadow: inset 0 1px 2px ${alpha(theme.colors.error.dark, 0.2)};
        }
        
        & .${linearProgressClasses.bar} {
            height: 12px;
            margin-top: 7px;
            border-radius: ${theme.general.borderRadiusSm};
            background: ${theme.colors.gradients.red1};
        }
    `
);

interface IProps {
    match: IMatch | null;
    attendingCount: number;
    totalEnrollments: number;
    modifiedDate: Date | null;
    showMatchDetails: () => void;
    isLoading?: boolean;
}

const MatchAvailability: React.FC<IProps> = (props) => {
    const theme = useTheme();

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 34, height: 34, display: 'inline-block;', marginRight: '5px', verticalAlign: 'middle' }}
        />
            <b style={{ fontSize: '14px', textAlign: 'center' }}>{team.name}</b>
        </>);
    }

    const data = {
        percentage2: Math.round(((props.attendingCount / props.totalEnrollments) * 100)),
    };
    const match = props.match;
    const isLoading = props.isLoading || match == null;
    return (
        <Card>
            <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box display="flex" alignItems="center">
                    <AvatarPrimary
                        sx={{
                            mr: 1.5
                        }}
                    >
                        <SportsSoccerIcon />
                    </AvatarPrimary>
                    <Typography variant="h4">VOLGENDE WEDSTRIJD</Typography>
                </Box>
            </Box>
            {isLoading && (<>
                <Loader />
            </>) || (match != null) && (<>
                <Box
                    sx={{
                        mx: 'auto',
                        mt: 2,
                        maxWidth: '90%'
                    }}
                >
                    <Box sx={{ display: 'flex' }}>
                        <Typography
                            sx={{
                                color: `${(data.percentage2 <= 50) ? theme.colors.error.main : theme.colors.success.main}`
                            }}
                            variant="h3"
                        >
                            {data.percentage2}%
                        </Typography>
                        <Typography
                            sx={{
                                color: `${(data.percentage2 <= 50) ? theme.colors.error.main : theme.colors.success.main}`,
                                alignSelf: 'end',
                                fontStyle: 'italic',
                                marginLeft: 'auto',
                                fontSize: '14px',
                                fontWeight: '400'
                            }}
                            variant="h6"
                        >
                            ({props.attendingCount} van de {props.totalEnrollments} spelers)
                        </Typography>
                    </Box>
                    {(data.percentage2 <= 50)
                        ? <LinearProgressRed sx={{ my: 1, }} variant="determinate" value={data.percentage2} />
                        : <LinearProgressGreen sx={{ my: 1, }} variant="determinate" value={data.percentage2} />
                    }
                </Box>
                <Box
                    sx={{
                        p: 2
                    }}>
                    <div style={{ fontStyle: "italic", marginTop: '-25px' }}>{props.modifiedDate != null && (<>
                        Laatst bijgewerkt {moment.utc(props.modifiedDate).local().fromNow()}
                    </>)}
                    </div>
                    <Divider style={{ margin: 10 }} />
                    <div><b>Volgende wedstrijd:</b></div><div> {match.team.name} - {match.opponentTeam.name} op {new Date(match.matchDate).toLocaleDateString()}</div>
                </Box>
                <Divider />
                <Box width="100%" sx={{ padding: '0px 15px;' }}>
                    <Grid sx={{ display: 'flex', justifyContent: 'space-around', paddingTop: '10px' }}>
                        <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '200px !important' }}>
                            {renderTeam(match.team)}
                        </Grid>
                        <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '60px !important', textAlign: 'center' }}>
                                <h2>{new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h2>
                        </Grid>
                        <Grid item xs={12} md="auto" sx={{ display: 'flex', flexFlow: 'column', alignItems: 'center', width: '200px !important' }}>
                            {renderTeam(match.opponentTeam)}
                        </Grid>
                    </Grid>
                </Box>
              
                <Divider />
                <Box
                    p={2}
                    sx={{
                        textAlign: 'center'
                    }}
                >
                    <Button
                        sx={{
                            width: '100%',
                            textTransform: 'uppercase',
                            fontSize: `${theme.typography.pxToRem(12)}`
                        }}
                        variant="contained"
                        endIcon={<KeyboardArrowRightTwoToneIcon />}
                        onClick={() => props.showMatchDetails()}
                    >
                        Toon alle spelers
                    </Button>
                </Box>
            </>)}
        </Card>
    );
}

export default MatchAvailability;
