import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Box, Card, Checkbox, Chip, TablePagination, Divider, Typography, Grid, IconButton, Avatar, styled, TextField, Tooltip, InputAdornment, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomLabel from '../../CustomLabel';

import { IUserRole, IUser, IMatch, ITeam } from 'entities';

// ICONS
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ImagePath } from '../../../images';

interface IProps {
    matches: IMatch[];
    filteredItems: IMatch[];
    query: string;
    selectedItems: string[];
    isSelectedBulkActions: boolean;
    isSelectedAll: boolean;
    isSelectedSome: boolean;
    handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectOne: (event: React.ChangeEvent<HTMLInputElement>, userId: string) => void;
    onUpdateSearchQuery: (query: string) => void;
}

const CardWrapper = styled(Card)(
    ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

const MatchesGrid: React.FC<IProps> = (props) => {
    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate();

    const [page, setPage] = React.useState<number>(0);
    const [limit, setLimit] = React.useState<number>(10);

    const handlePageChange = (_event: any, newPage: number): void => setPage(newPage);
    const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>): void => setLimit(parseInt(event.target.value));

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        event.persist();
        props.onUpdateSearchQuery(event.target.value);
    };

    const renderTeam = (team: ITeam) => {
        return (<><Avatar
            src={ImagePath(team.logoUrl)}
            sx={{ width: 24, height: 24, display: 'inline-block;', marginRight: '15px', verticalAlign: 'middle' }}
        />
            <b style={{ fontSize: '16px' }}>{team.name}</b>
        </>);
    }

    const paginatedMatches = props.filteredItems; // props.matches;

    return (<>
        {paginatedMatches.length === 0 ? (
            <Typography
                sx={{
                    py: 10
                }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
            >
                {t("We hebben geen wedstrijden gevonden.")}
            </Typography>
        ) : (<>
            <Grid container spacing={3}>
                {paginatedMatches.map((match) => {
                    const isUserSelected = props.selectedItems.includes(match.id.toString());
                    return (
                        <Grid item xs={12} sm={6} md={4} key={match.id}>
                            <CardWrapper className={isUserSelected ? 'Mui-selected' : ''}>
                                <Box sx={{ position: 'relative', zIndex: '2' }}  >
                                    <Box px={2} pt={2} display="flex" alignItems="flex-start" justifyContent="space-between">
                                        <Box pt={2} display="flex" alignItems="flex-start" justifyContent="space-between">
                                            <CustomLabel color="success">
                                                <DoneTwoToneIcon fontSize="small" />
                                                <b>{t('Actief')}</b>
                                            </CustomLabel>
                                        </Box>
                                        <IconButton color="primary" sx={{ p: 0.5 }}  >
                                            <MoreVertTwoToneIcon />
                                        </IconButton>
                                    </Box>
                                    <Box p={2} display="flex" alignItems="flex-start">
                                        <Box>
                                            <Box>
                                                <Grid>
                                                    <Grid item xs={12} md="auto" sx={{ width: '200px !important' }}>
                                                        {renderTeam(match.team)}
                                                    </Grid>
                                                    <Grid item xs={12} md="auto" sx={{ width: '200px !important' }}>
                                                        {renderTeam(match.opponentTeam)}
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Typography variant="body1" color="text.secondary" sx={{ marginTop: 2 }}>
                                                <div><b>Wanneer: </b>
                                                    {moment(match.matchDate).isBetween(moment().subtract(5, 'days'), moment().add(10, 'days')) && (<>
                                                        {moment(match.matchDate).fromNow()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                    </>) || (<>
                                                        {new Date(match.matchDate).toLocaleDateString()} om {new Date(match.matchDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                    </>)}
                                                </div>
                                                <div><b>Adres:</b> {match.location}</div>
                                                <div><b>Aanwezig:</b> {match.enrollments.filter(e => e.status == "Aanwezig" && e.membership.type === "Speler").length} spelers(s)</div>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Divider />
                                    <Box pl={2} py={1} pr={1} display="flex" alignItems="center" justifyContent="space-between">
                                        <div>
                                            <Button sx={{ marginRight: '10px' }} variant="contained" size="small"
                                                onClick={() => navigate(`/manage/match/${match.id}/details`)}
                                                startIcon={<EditIcon />}>
                                                Wijzig
                                            </Button>
                                            <Button variant="outlined" size="small" color="error"
                                                onClick={() => console.log("delete function not implemented")}
                                                startIcon={<DeleteIcon />}>
                                                Verwijder
                                            </Button>
                                        </div>
                                        {/*<Checkbox*/}
                                        {/*    checked={isUserSelected}*/}
                                        {/*    onChange={(event) =>*/}
                                        {/*        props.handleSelectOneUser(event, user.id.toString())*/}
                                        {/*    }*/}
                                        {/*    value={isUserSelected}*/}
                                        {/*/>*/}
                                    </Box>
                                </Box>
                            </CardWrapper>
                        </Grid>
                    );
                })}
                </Grid>
                
            <Card sx={{ p: 2, mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {/*<TablePagination*/}
                {/*    component="div"*/}
                {/*    count={props.filteredUsers.length}*/}
                {/*    onPageChange={handlePageChange}*/}
                {/*    onRowsPerPageChange={handleLimitChange}*/}
                {/*    page={page}*/}
                {/*    rowsPerPage={limit}*/}
                {/*    labelRowsPerPage=""*/}
                {/*    rowsPerPageOptions={[5, 10, 15]}*/}
                {/*/>*/}
            </Card>
        </>
        )}
    </>);
};

export default MatchesGrid;
