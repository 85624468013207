import * as React from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Grid, Button } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { getClubMatches } from "services/matchService";
import { IMatch } from 'entities';
import { Matches, MatchEnrollments } from 'components/Events'
import MatchSummary from '../Events/MatchSummary';
import MimavevaPage from '../MimavevaPage';
import Footer from '../Footer';

// ICONS
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import EditIcon from '@mui/icons-material/Edit'

import styles from 'styles/modules/app.module.scss';
import { useNavigate } from 'react-router';

const MatchesAgenda: React.FC = () => {
    const { user, membership, isLoadingAuth } = useAuth();
    const [matches, setMatches] = React.useState<IMatch[]>([]);
    const [upcomingMatches, setUpcomingMatches] = React.useState<IMatch[]>([]);
    const [selectedMatch, setSelectedMatch] = React.useState<IMatch | null>(null);

    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    React.useEffect(() => {
        if (membership === null) {
            return;
        }
        setIsLoading(true);

        // Load the matches of the current membership club.
        getClubMatches(membership.team.id).then(data => {
            if (data === null) {
                return;
            }
            const now = moment();
            const filteredMatches = data.filter(d => moment(d.matchDate).isSameOrAfter(now, 'days'));
            const playedMatches = data.filter(d => !filteredMatches.includes(d));

            const sortedMatches = [...filteredMatches].sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate)));
            const sortedplayedMatches = [...playedMatches].sort((a, b) => moment(a.matchDate).diff(moment(b.matchDate))).reverse();

            setUpcomingMatches(sortedMatches);
            setMatches(sortedplayedMatches)
            setIsLoading(false);
        });

    }, [membership])

    const updateMatch = (match: IMatch) => {
        const updatedMatchArray = matches.map(m =>
            m.id === match.id
                ? { ...m, match }
                : m
        );
        setMatches(updatedMatchArray);
    }

    const isLoadingAll = isLoading || isLoadingAuth;
    return (
        <MimavevaPage>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Wedstrijden</title>
                </Helmet>
                <Container>
                    {(user != null && membership != null) && (<>
                        {(selectedMatch != null) && (<>
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={4}
                            >
                                <Grid item xs={6} md={6}>
                                    <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 2 }}
                                        variant="contained"
                                        endIcon={<KeyboardReturnIcon />}
                                        onClick={() => setSelectedMatch(null)}
                                    >Terug
                                    </Button>
                                </Grid>
                                <Grid item xs={6} md={6}>
                                    {user.role === "Admin" && (
                                        <Button sx={{ textTransform: 'uppercase', fontSize: 12, marginBottom: 2, float: 'right' }}
                                            variant="outlined"
                                            onClick={() => navigate(`/manage/match/${selectedMatch.id}/details`)}
                                            startIcon={<EditIcon />}>
                                            Wijzig
                                        </Button>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ paddingTop: '0!important' }}>
                                    <MatchEnrollments match={selectedMatch} enrollments={selectedMatch.enrollments} />
                                </Grid>
                            </Grid>
                        </>)}
                        {(selectedMatch === null) && (
                            <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="stretch"
                                spacing={4}
                                marginBottom={5}
                            >
                                <Grid item xs={12} md={6}>
                                    <Matches title={`AANKOMENDE WEDSTRIJDEN`}
                                        membership={membership}
                                        isLoading={isLoadingAll}
                                        userId={user.id}
                                        matches={upcomingMatches}
                                        limit={99999}
                                        showMatchDetails={(match: IMatch) => setSelectedMatch(match)}
                                        updateMatch={(match: IMatch) => updateMatch(match)}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MatchSummary title={`GESPEELDE WEDSTRIJDEN`}
                                        membership={membership}
                                        isLoading={isLoadingAll}
                                        userId={user.id}
                                        matches={matches}
                                        limit={99999}
                                        showMatchDetails={(match: IMatch) => { setSelectedMatch(match) }}
                                        updateMatch={() => { }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </>)}
                </Container>
                <Footer />
            </Box>
        </MimavevaPage>
    );
}

export default MatchesAgenda;