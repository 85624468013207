import * as React from 'react';

import { FC, createContext } from 'react';
import { IMembership, IUser } from '../entities';
import { getMembership } from '../services/membershipService';

type AuthContext = {
    isAuthenticated: boolean;
    isLoadingAuth: boolean;
    isLoadingMembership: boolean;
    logout: () => void;
    login: () => void;
    user: IUser | null;
    reloadAuthUser: () => void;
    membership: IMembership | null;
    loadMembership: () => void;
};

//// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuthContext = createContext<AuthContext>(
    {} as AuthContext
);
export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider: FC = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<IUser | null>(null);
    const [membership, setMembership] = React.useState<IMembership | null>(null);
    const [isLoadingAuth, setIsLoading] = React.useState(false);
    const [isLoadingMembership, setIsLoadingMembership] = React.useState(false);

    React.useEffect(() => {
        if (isLoadingAuth) {
            return;
        }
        if (isAuthenticated && user != null) {
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const authorizeRequest = async (): Promise<IUser> => {
            const response = await fetch('/auth/authorize', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            const data = await response.json();
            return data;
        }

        const fetchAuthorizeUser = async (): Promise<IUser> => {
            const response = await fetch('/user/my', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return await response.json();
        }

        authorizeRequest().then(data => {
            console.log("authorize call successfully")
            setIsAuthenticated(data.isAuthenticated);
            setIsLoading(false);

            // Login when not authenticated.
            if (!data.isAuthenticated) {
                window.location.href = '/auth/login';
            } else {

                // Read user details.
                fetchAuthorizeUser().then(data => {
                    console.log("user loaded successfully")
                    setUser(data);
                    setIsAuthenticated(data.isAuthenticated);
                    setIsLoading(false);
                });
            }

        }).catch(error => {
            console.log(error);
        });

    }, [user, isAuthenticated]);

    const loadMembership = (): void => {
        if (membership != null || user == null) {
            return;
        }
        setIsLoadingMembership(true);
        // Load the membership of the current user.
        getMembership().then(data => {
            console.log("membership loaded successfully")
            setMembership(data);
            setIsLoadingMembership(false)
        });
    }

    const reloadAuthUser = (): void => {
        // Read user details
        const fetchDataUser = async (): Promise<IUser> => {
            const response = await fetch('/user/my', {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });
            return await response.json();
        }
        fetchDataUser().then(data => {
            console.log("user loaded successfully")
            setUser(data);
        });
    }

    const login = () => {
        if (isLoadingAuth) {
            return;
        }
        window.location.href = '/auth/login';
    }

    const logout = () => {
        window.location.href = '/auth/logout';
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                reloadAuthUser,
                isAuthenticated,
                isLoadingAuth,
                isLoadingMembership,
                membership,
                loadMembership,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
