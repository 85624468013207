import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, Divider, Typography, styled, Avatar, Grid, CardContent } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';

// IMAGES
import RabobankPay from 'images/button-pay-rabobank.png';

// ICONS
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import PaymentIcon from '@mui/icons-material/Payment';

import styles from 'styles/modules/app.module.scss';
import MimavevaPage from '../MimavevaPage';
import { PropertyService } from '../../services/propertyService';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const UserFinance: React.FC = () => {
    const { user, membership } = useAuth();

    const [isLoading, setIsLoading] = React.useState(false);
    const [paymentLink, setPaymentLink] = React.useState<string>("");

    React.useEffect(() => {
        setIsLoading(true);
        const propertyService = new PropertyService();
        propertyService.getProperty("Mimaveva.PaymentLink").then(data => {
            if (data === null) {
                setIsLoading(false);
                return;
            }
            setPaymentLink(data.value);
            setIsLoading(false);
        })
    }, [])

    return (
        <MimavevaPage>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Sparen</title>
                </Helmet>
                <Container>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={4}
                        marginBottom={5}
                    >
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined">
                                <Box
                                    p={2}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex" alignItems="center">
                                        <AvatarPrimary sx={{ mr: 1.5 }}>
                                            <EuroSymbolIcon />
                                        </AvatarPrimary>
                                        <Typography variant="h4">OPENSTAAND BEDRAG</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                {user != null && membership != null && (
                                    <Card>
                                        <CardContent>
                                            <Typography>
                                                <div>
                                                    Uit onze administratie blijkt dat er nog: <b>€{membership.amountDue}</b> open staat voor <b>{user.username}</b>.
                                                </div><br />
                                                Je kan dit betalen door:
                                                <ul>
                                                    <li>Op de betaal link te klikken.</li>
                                                    <li>Door het over te maken naar <b>NL50 RABO 0358 5011 64</b> t.n.v <b>M. Platvoet</b> voetbal onder vermelding van <b>Sparen Sv Reeshof 7</b>.</li>
                                                </ul>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                )}
                            </Card>

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card variant="outlined">
                                <Box
                                    p={2}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Box display="flex" alignItems="center">
                                        <AvatarPrimary sx={{ mr: 1.5 }}>
                                            <PaymentIcon />
                                        </AvatarPrimary>
                                        <Typography variant="h4">BETALEN</Typography>
                                    </Box>
                                </Box>
                                <Divider />
                                {user != null && membership != null && (
                                    <a href={paymentLink}>
                                        <Box style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', margin: '20px', maxWidth: '280px' }}>
                                            <img alt="pay-with-rabobank" src={RabobankPay} width="90%"  />
                                        </Box>
                                    </a>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </MimavevaPage>
    );
}

export default UserFinance;