import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Card, Divider, Typography, styled, Avatar, Grid, Button, CardContent } from '@mui/material';
import { useAuth } from 'contexts/AuthContext';
import { UserService } from 'services/userService';
import { MembershipService } from 'services/membershipService';
import { IMembership, IMembershipType, IUser } from 'entities';

// ICONS
import PaymentIcon from '@mui/icons-material/Payment';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

import styles from 'styles/modules/app.module.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.primary.lighter};
      color: ${theme.palette.primary.main};
      width: ${theme.spacing(5)};
      height: ${theme.spacing(5)};
`
);

const CardWrapper = styled(Card)(
    ({ theme }) => `

  position: relative;
  overflow: visible;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    z-index: 1;
    transition: ${theme.transitions.create(['box-shadow'])};
  }
      
    &.Mui-selected::after {
      box-shadow: 0 0 0 3px ${theme.colors.primary.main};
    }
  `
);

interface IRequestMembershipUser {
    user: IUser;
    membership: IMembership;
}

const ManageMembershipRequests: React.FC = () => {
    const { t }: { t: any } = useTranslation();
    const [requestmembershipUsers, setRequestmembershipUsers] = React.useState<IRequestMembershipUser[]>([]);

    const membershipService = new MembershipService();

    React.useEffect(() => {
        // Read user details with memberships.
        let requestmembershipList: IRequestMembershipUser[] = [];
        membershipService.getMembershipRequests().then(m => {
            if (m == null) {
                return;
            }
            m.forEach(membership => requestmembershipList.push({ user: membership.user, membership }))
            setRequestmembershipUsers(requestmembershipList);
        });
    }, [])

    const onUpdate = (userId: number, membership: IMembership): void => {
        const editmembership = { ...membership, type: "Speler" as IMembershipType }

        // Save the edited membership.
        membershipService.updateMembership(userId, editmembership);

        const updatedArray = requestmembershipUsers.filter(m => m.user.id != userId);
        setRequestmembershipUsers(updatedArray);
    }

    return (
        <Box className={styles.overview}>
            <Helmet>
                <title>Overzicht aanvragen</title>
            </Helmet>
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={4}
                    marginBottom={5}
                >

                    <Grid item xs={12} md={12}>
                        <Card variant="outlined">
                            <Box
                                p={2}
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box display="flex" alignItems="center">
                                    <AvatarPrimary sx={{ mr: 1.5 }}>
                                        <PaymentIcon />
                                    </AvatarPrimary>
                                    <Typography variant="h4">AANVRAGEN LIDMAATSCHAP</Typography>
                                </Box>
                            </Box>
                            <Divider />
                            <CardContent>
                                <Typography>
                                    Openstaande aanvragen van gebruikers voor een lidmaatschap voor het team.
                                    Klik op "Accepteren" om de gebruiker als lid toe te voegen aan het team.
                                </Typography>
                            </CardContent>
                            {requestmembershipUsers.length === 0 ? (
                                <Typography
                                    sx={{
                                        py: 10
                                    }}
                                    variant="h3"
                                    fontWeight="normal"
                                    color="text.secondary"
                                    align="center"
                                >
                                    {t("We hebben geen aanvragen gevonden.")}
                                </Typography>
                            ) :
                                (<Grid sx={{ display: 'flex', flexFlow: 'wrap'}}>
                                    {requestmembershipUsers.map(data => (
                                        <Grid item xs={12} sm={4} md={4} key={data.user.id}>
                                            <CardWrapper sx={{ margin: '20px' }} >
                                                <Box sx={{ position: 'relative', zIndex: '2' }}>
                                                    <Box p={2} display="flex" alignItems="flex-start">
                                                        <Avatar sx={{ width: 50, height: 50, mr: 2 }} src={data.user.avatar} />
                                                        <Box>
                                                            <Box>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    color="text.secondary"
                                                                >
                                                                    ({data.user.username})
                                                                </Typography>
                                                            </Box>
                                                            <Typography sx={{ pt: 0.3 }} variant="subtitle2">
                                                                {data.user.jobtitle}
                                                            </Typography>
                                                            <Typography sx={{ pt: 1 }} variant="h6">
                                                                {data.user.email}
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                style={{ fontStyle: 'italic' }}
                                                                color="text.secondary"
                                                            >
                                                                {data.user.latestLogin ? moment(data.user.latestLogin).local().fromNow() : 'nog nooit'} voor het laatst ingelogd
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider />
                                                    <Box pl={2} py={1} pr={1} display="flex" alignItems="center" justifyContent="space-between">
                                                        <div>
                                                            <Button sx={{ marginRight: '10px' }} variant="contained" size="small"
                                                                onClick={() => onUpdate(data.user.id, data.membership)}
                                                                startIcon={<DoneIcon />}>
                                                                Accepteren
                                                            </Button>
                                                            <Button variant="outlined" size="small" color="error"
                                                                onClick={() => console.log("delete function not implemented")}
                                                                startIcon={<ClearIcon />}>
                                                                Weigeren
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </CardWrapper>
                                        </Grid>))}
                                </Grid>)}
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default ManageMembershipRequests;