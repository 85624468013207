import * as React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Card, Slide, Button, Typography, Dialog, styled, Container, CardContent, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';
import { IUser, IUserRole } from 'entities';
import { UserService } from "services/userService"
import { useAuth } from 'contexts/AuthContext';

import ManageUsersTabs from "./ManageUsersTabs";
import ManageUsersTable from "./ManageUsersTable";
import ManageUsersGrid from "./ManageUsersGrid"

import styles from 'styles/modules/app.module.scss';
import Footer from 'components/Footer';
import Loader from '../../Loader';
import { useParams } from 'react-router';

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }`
);
const AvatarError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }`
);
const ButtonError = styled(Button)(
    ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }`
);

interface Filters {
    role?: IUserRole;
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (users: IUser[], query: string, filters: Filters, isInactiveVisible: boolean, isAdmin: boolean): IUser[] => {
    return users.filter((user) => {
        let matches = true;

        if (query) {
            const properties = ['email', 'username'];
            let containsQuery = false;

            properties.forEach((property) => {
                if (user[property].toLowerCase().includes(query.toLowerCase())) {
                    containsQuery = true;
                }
            });

            if (filters.role && user.role !== filters.role) {
                matches = false;
            }

            if (!containsQuery) {
                matches = false;
            }
        }

        // Filter on inactive users
        if (!user.isActive && !isInactiveVisible) {
            matches = false;
        }

        // Filter out admin users
        //if (filters.role !== "Admin" && user.role === "Admin") {
        //    matches = false;
        //}

        // Filter out aanraag users
        if (filters.role !== "Aanvraag" && user.role === "Aanvraag") {
            matches = false;
        }

        Object.keys(filters).forEach((key: string) => {
            const value = filters[key as keyof Filters];
            if (value && user[key] !== value) {
                matches = false;
            }
        });

        return matches;
    });
};

const Results: React.FC = () => {
    const { user } = useAuth();
    const { pageNumber } = useParams();
    const [isLoading, setIsLoading] = React.useState(false);
    const [users, setUsers] = React.useState<IUser[]>([]);
    const [isInactiveVisible, setIsInactiveVisible] = React.useState(false);

    const userService = new UserService();

    React.useEffect(() => {
        // Read user details = todo make this smarter :)
        setIsLoading(true);
        userService.getUsers().then(data => {
            setUsers(data || []);
            setIsLoading(false);
        });
    }, [])

    const [selectedItems, setSelectedUsers] = React.useState<string[]>([]);
    const { t }: { t: any } = useTranslation();

    const [query, setQuery] = React.useState<string>('');
    const [filters, setFilters] = React.useState<Filters>({
        role: null
    });

    const handleTabsChange = (_event: React.SyntheticEvent, tabsValue: unknown) => {
        let value: IUserRole = null;

        if (tabsValue !== 'all') {
            value = tabsValue as IUserRole;
        }

        setFilters((prevFilters) => ({
            ...prevFilters,
            role: value
        }));

        setSelectedUsers([]);
    };

    const handleSelectAllUsers = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSelectedUsers(event.target.checked ? users.map((user) => user.id.toString()) : []);
    };

    const handleSelectOneUser = (
        _event: React.ChangeEvent<HTMLInputElement>,
        userId: string
    ): void => {
        if (!selectedItems.includes(userId)) {
            setSelectedUsers((prevSelected) => [...prevSelected, userId]);
        } else {
            setSelectedUsers((prevSelected) =>
                prevSelected.filter((id) => id !== userId)
            );
        }
    };

    const filteredUsers = applyFilters(users, query, filters, isInactiveVisible, user?.role === "Admin");
    const selectedBulkActions = selectedItems.length > 0;
    const selectedSomeUsers = selectedItems.length > 0 && selectedItems.length < users.length;
    const selectedAllUsers = selectedItems.length === users.length;

    const [toggleView, setToggleView] = React.useState<string | null>('table_view');
    const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);

    const closeConfirmDelete = () => setOpenConfirmDelete(false);
    const handleDeleteCompleted = () => setOpenConfirmDelete(false);

    //choose the screen size 
    React.useEffect(() => {
        if (window.innerWidth < 720) {
            setToggleView("grid_view");
        }
    }, []);

    return (
        <>
            <Box className={styles.overview}>
                <Helmet>
                    <title>Gebruikers beheren</title>
                </Helmet>
                <Container>
                    <Card>
                        <CardContent>
                            <ManageUsersTabs toggleView={toggleView} filters={filters}
                                updateView={(view) => setToggleView(view)}
                                onTabChange={handleTabsChange}
                            />
                            <FormGroup>
                                <FormControlLabel sx={{ display: 'flex', justifyContent: 'end', marginTop: '-40px' }}
                                    control={<Checkbox checked={isInactiveVisible}
                                        onChange={() => setIsInactiveVisible(!isInactiveVisible)} />}
                                        label="Inactieve gebruikers"
                                />
                            </FormGroup>
                            {isLoading && (<>
                                <Loader />
                            </>) || (<>
                                {toggleView === 'table_view' && (
                                    <ManageUsersTable users={users} query={query}
                                        pageNumber={pageNumber ? Number(pageNumber) : 0 }
                                        filteredUsers={filteredUsers}
                                        selectedItems={selectedItems}
                                        isSelectedBulkActions={selectedBulkActions}
                                        isSelectedAllUsers={selectedAllUsers}
                                        isSelectedSomeUsers={selectedSomeUsers}
                                        handleSelectAllUsers={handleSelectAllUsers}
                                        handleSelectOneUser={handleSelectOneUser}
                                        onUpdateSearchQuery={(query: string) => setQuery(query)}
                                    />
                                )}
                                {toggleView === 'grid_view' && (<>
                                    <ManageUsersGrid users={users} query={query}
                                        pageNumber={pageNumber ? Number(pageNumber) : 0}
                                        filteredUsers={filteredUsers}
                                        selectedItems={selectedItems}
                                        isSelectedBulkActions={selectedBulkActions}
                                        isSelectedAllUsers={selectedAllUsers}
                                        isSelectedSomeUsers={selectedSomeUsers}
                                        handleSelectAllUsers={handleSelectAllUsers}
                                        handleSelectOneUser={handleSelectOneUser}
                                        onUpdateSearchQuery={(query: string) => setQuery(query)}
                                    />
                                </>)}
                            </>)}
                            {!toggleView && (
                                <Card sx={{ textAlign: 'center', p: 3 }}>
                                    <Typography
                                        align="center"
                                        variant="h4"
                                        fontWeight="normal"
                                        color="text.secondary"
                                        sx={{
                                            my: 5
                                        }}
                                        gutterBottom
                                    >
                                        {t('Choose between table or grid views for displaying the users list.')}
                                    </Typography>
                                </Card>
                            )}
                            <DialogWrapper
                                open={openConfirmDelete}
                                maxWidth="sm"
                                fullWidth
                                TransitionComponent={Transition}
                                keepMounted
                                onClose={closeConfirmDelete}
                            >
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexDirection="column"
                                    p={5}
                                >
                                    <AvatarError>
                                        <CloseIcon />
                                    </AvatarError>

                                    <Typography
                                        align="center"
                                        sx={{
                                            py: 4,
                                            px: 6
                                        }}
                                        variant="h3"
                                    >
                                        {t('Are you sure you want to permanently delete this user account')} ?
                                    </Typography>

                                    <Box>
                                        <Button
                                            variant="text"
                                            size="large"
                                            sx={{
                                                mx: 1
                                            }}
                                            onClick={closeConfirmDelete}
                                        >
                                            {t('Cancel')}
                                        </Button>
                                        <ButtonError
                                            onClick={handleDeleteCompleted}
                                            size="large"
                                            sx={{
                                                mx: 1,
                                                px: 3
                                            }}
                                            variant="contained"
                                        >
                                            {t('Delete')}
                                        </ButtonError>
                                    </Box>
                                </Box>
                            </DialogWrapper>
                        </CardContent>
                    </Card>
                </Container>
                <Footer />
            </Box>
        </>
    );
};

Results.propTypes = {
    users: PropTypes.array.isRequired
};

Results.defaultProps = {
    users: []
};

export default Results;
